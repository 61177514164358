@charset "UTF-8";
/*
	Obecny stylesheet webu TYMA CZ
*/
.form-newsletter-content form,
.fulltext_results form, .coupon, .shopping-main, .sidebar__items p, .sidebar__items > div, .shop-sidebar, .sidebar__newsletter-box form label.label-email, .sidebar__newsletter-box form, .sidebar__info-box, .footer-bottom, .footer-middle, .contact-box, .partner-box .fbox-1, .footer-top, .search-items, .header-bottom, .header-top, .container {
  zoom: 1;
}
.form-newsletter-content form:before,
.fulltext_results form:before, .coupon:before, .shopping-main:before, .sidebar__items p:before, .sidebar__items > div:before, .shop-sidebar:before, .sidebar__newsletter-box form label.label-email:before, .sidebar__newsletter-box form:before, .sidebar__info-box:before, .footer-bottom:before, .footer-middle:before, .contact-box:before, .partner-box .fbox-1:before, .footer-top:before, .search-items:before, .header-bottom:before, .header-top:before, .container:before, .form-newsletter-content form:after,
.fulltext_results form:after, .coupon:after, .shopping-main:after, .sidebar__items p:after, .sidebar__items > div:after, .shop-sidebar:after, .sidebar__newsletter-box form label.label-email:after, .sidebar__newsletter-box form:after, .sidebar__info-box:after, .footer-bottom:after, .footer-middle:after, .contact-box:after, .partner-box .fbox-1:after, .footer-top:after, .search-items:after, .header-bottom:after, .header-top:after, .container:after {
  content: "";
  display: table;
}
.form-newsletter-content form:after,
.fulltext_results form:after, .coupon:after, .shopping-main:after, .sidebar__items p:after, .sidebar__items > div:after, .shop-sidebar:after, .sidebar__newsletter-box form label.label-email:after, .sidebar__newsletter-box form:after, .sidebar__info-box:after, .footer-bottom:after, .footer-middle:after, .contact-box:after, .partner-box .fbox-1:after, .footer-top:after, .search-items:after, .header-bottom:after, .header-top:after, .container:after {
  clear: both;
}

.contact-links-box li:before, .menu-list li:before, .header-logged.is-visible li:before,
.header-logged.is-visible .logout-link:before {
  display: none;
}

.gdpr-field [type=checkbox]:not(:checked)#osobni-souhlas + .label-helper > label:before, .highlight {
  outline: 5px solid #f5c398 !important;
  outline-offset: 0;
}

.submenu-box h3, .header-top {
  background: #007bc4;
  color: #fff;
}
.submenu-box h3 a, .header-top a {
  color: #fff;
}

/* ====== Sections ====== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/* ====== Grouping content ====== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main { /* 1 */
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* ====== Text-level semantics ====== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
}

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0;
}

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted; /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* ====== Embedded content ====== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* ====== Forms ====== */
/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input { /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* ====== Interactive ====== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* ====== Scripting ====== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* ====== Hidden ====== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

/*
	definice webových fontů
*/
@font-face {
  font-family: "opensans";
  src: url("../../fonts/opensans-bold-webfont.eot");
  src: url("../../fonts/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/opensans-bold-webfont.woff2") format("woff2"), url("../../fonts/opensans-bold-webfont.woff") format("woff"), url("../../fonts/opensans-bold-webfont.ttf") format("truetype"), url("../../fonts/opensans-bold-webfont.svg#opensans") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
  font-family: "opensans";
  src: url("../../fonts/opensans-semibold-webfont.eot");
  src: url("../../fonts/opensans-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/opensans-semibold-webfont.woff2") format("woff2"), url("../../fonts/opensans-semibold-webfont.woff") format("woff"), url("../../fonts/opensans-semibold-webfont.ttf") format("truetype"), url("../../fonts/opensans-semibold-webfont.svg#opensans") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
  font-family: "opensans";
  src: url("../../fonts/opensans-regular-webfont.eot");
  src: url("../../fonts/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/opensans-regular-webfont.woff2") format("woff2"), url("../../fonts/opensans-regular-webfont.woff") format("woff"), url("../../fonts/opensans-regular-webfont.ttf") format("truetype"), url("../../fonts/opensans-regular-webfont.svg#opensans") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
  font-family: "opensans";
  src: url("../../fonts/opensans-bold-webfont.eot");
  src: url("../../fonts/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/opensans-bold-webfont.woff2") format("woff2"), url("../../fonts/opensans-bold-webfont.woff") format("woff"), url("../../fonts/opensans-bold-webfont.ttf") format("truetype"), url("../../fonts/opensans-bold-webfont.svg#opensans") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "opensans";
  src: url("../../fonts/opensans-semibold-webfont.eot");
  src: url("../../fonts/opensans-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/opensans-semibold-webfont.woff2") format("woff2"), url("../../fonts/opensans-semibold-webfont.woff") format("woff"), url("../../fonts/opensans-semibold-webfont.ttf") format("truetype"), url("../../fonts/opensans-semibold-webfont.svg#opensans") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "opensans";
  src: url("../../fonts/opensans-regular-webfont.eot");
  src: url("../../fonts/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/opensans-regular-webfont.woff2") format("woff2"), url("../../fonts/opensans-regular-webfont.woff") format("woff"), url("../../fonts/opensans-regular-webfont.ttf") format("truetype"), url("../../fonts/opensans-regular-webfont.svg#opensans") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "adjusted-arial";
  src: local(Arial);
  size-adjust: 99%;
  ascent-override: 103%;
  descent-override: 29%;
  line-gap-override: normal;
}
/*
    obecne definice prvku
*/
html {
  margin: 0;
  padding: 0;
  font-family: "opensans", "adjusted-arial", Helvetica, sans-serif;
  font-size: 14px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  max-height: 1000000000000000000000px;
}

*::-moz-selection {
  background: #c0cfd0;
  color: #161616;
}

*::selection {
  background: #c0cfd0;
  color: #161616;
}

a,
area,
button,
input,
label,
select,
.nice-select,
summary,
textarea {
  touch-action: manipulation;
}

/* zrusime oramovani aktivniho textu */
a:focus {
  outline: none;
}

body {
  font-family: "opensans", "adjusted-arial", Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.5714285714;
  color: #161616;
  background: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.body-helper {
  position: relative;
}

h1 {
  margin: 1.2em 0 0.52em 0;
  font-size: 2.286rem;
  font-family: "opensans", "adjusted-arial", Helvetica, sans-serif;
  color: #161616;
  font-weight: 900;
  line-height: 1.2;
}

h2,
.h2like {
  margin: 1.6em 0 0.8em 0;
  font-size: 1.286rem;
  font-family: "opensans", "adjusted-arial", Helvetica, sans-serif;
  color: #007bc4;
  font-weight: 700;
  line-height: 1.2;
}
h1 + h2,
h1 + .h2like {
  margin-top: 1em;
}
h2.h2-big,
.h2like.h2-big {
  margin: 2.4em 0 0.8em 0;
  font-size: 1.714rem;
  font-weight: 700;
  color: #161616;
}
h2.h2-big--mt-init,
.h2like.h2-big--mt-init {
  margin-top: 1.6em;
}

h3 {
  margin: 1.6em 0 0.8em 0;
  font-size: 1.143rem;
  font-family: "opensans", "adjusted-arial", Helvetica, sans-serif;
  color: #161616;
  font-weight: 700;
  line-height: 1.2;
}
h2 + h3 {
  margin-top: 1em;
}
h3.h3-big {
  margin: 2.4em 0 0.8em 0;
  font-size: 1.429rem;
  font-weight: 700;
  color: #007bc4;
}

h4 {
  margin: 1.6em 0 0.8em 0;
  font-size: 1rem;
  font-family: "opensans", "adjusted-arial", Helvetica, sans-serif;
  color: #007bc4;
  font-weight: 700;
  line-height: 1.2;
}
h3 + h4 {
  margin-top: 1em;
}

p {
  margin: 0 0 1em 0;
}

ul,
ol ul {
  margin: 1em 0;
  padding-left: 1em;
}
ul li,
ol ul li {
  position: relative;
  margin: 0 0 0.714em 0;
  padding-left: 1.357em;
  list-style-type: none;
}
ul li:before,
ol ul li:before {
  display: block;
  position: absolute;
  left: 0;
  top: 0.5em;
  width: 0.5em;
  height: 0.5em;
  background: #007bc4;
  content: "";
}

ol {
  margin: 1em 0;
  padding-left: 1.9em;
}
ol li {
  margin: 0 0 0.714em 0;
  padding-left: 0.48em;
}

a {
  color: #1d4259;
}
a:hover, a:focus {
  color: #007bc4;
  text-decoration: none;
}

strong {
  font-weight: 700;
}

fieldset {
  margin: 25px 0;
  padding: 0;
  border: 0;
}
fieldset legend {
  margin-bottom: 8px;
  font-size: 1.143rem;
  font-weight: 700;
  color: #007bc4;
}

/* [1] odstraneni defaultniho nastaveni browseru */
input,
button,
textarea,
select,
.nice-select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  margin: 0;
  border-radius: 0;
  font-family: "opensans", "adjusted-arial", Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

input[type=text],
input[type=password],
input[type=search],
input[type=email],
input[type=tel],
input[type=number],
textarea {
  width: 100%;
  height: 3.429rem;
  border: 1px solid #cce5f3;
  border-radius: 0;
  padding: 12px 18px;
  background: #fff;
  color: #161616;
  box-shadow: none;
}
input[type=text]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=email]:focus,
input[type=tel]:focus,
input[type=number]:focus,
textarea:focus {
  outline: none;
  border: 1px solid #1d4259;
}
input[type=text]::-moz-placeholder, input[type=password]::-moz-placeholder, input[type=search]::-moz-placeholder, input[type=email]::-moz-placeholder, input[type=tel]::-moz-placeholder, input[type=number]::-moz-placeholder, textarea::-moz-placeholder {
  color: #adadad;
}
input[type=text]::placeholder,
input[type=password]::placeholder,
input[type=search]::placeholder,
input[type=email]::placeholder,
input[type=tel]::placeholder,
input[type=number]::placeholder,
textarea::placeholder {
  color: #adadad;
}

input[type=search] {
  -webkit-appearance: none;
}

input[type=submit],
button {
  cursor: pointer;
}
input[type=submit]:focus, input[type=submit]:active,
button:focus,
button:active {
  outline: none;
}

textarea {
  height: auto;
  overflow: auto;
  resize: vertical;
}

[type=checkbox] {
  position: absolute;
  opacity: 0;
}

[type=checkbox] + label {
  display: block;
  position: relative;
  padding-left: 36px;
}

[type=checkbox]:not(:disabled) + label {
  cursor: pointer;
}

[type=checkbox] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: -1px;
  width: 24px;
  height: 24px;
  border: 1px solid #cce5f3;
}

[type=checkbox]:not(:disabled) + label:before {
  cursor: pointer;
}

[type=checkbox]:focus + label:before {
  border-color: #1d4259;
}

[type=checkbox] + label:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 5px;
  width: 15px;
  height: 15px;
  background: url(../../gfx/check.svg) center center no-repeat;
  transition: all 0.1s;
}

[type=checkbox]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

[type=checkbox]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

[type=radio] {
  position: absolute;
  left: -99999px;
  overflow: hidden;
}

[type=radio] + label {
  display: block;
  position: relative;
  padding: 3px 0 3px 36px;
}

[type=radio]:not(:disabled) + label {
  cursor: pointer;
}

[type=radio] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 24px;
  height: 24px;
  margin: auto;
  border: 2px solid #dadada;
  border-radius: 50%;
}

[type=radio]:not(:disabled) + label:after {
  content: "";
  position: absolute;
  top: 9px;
  left: 6px;
  width: 12px;
  height: 12px;
  margin: auto;
  background: #007bc4;
  transition: all 0.1s;
  border-radius: 50%;
}

[type=radio]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type=radio]:not(:checked) + label:hover:after {
  background: #dadada;
  opacity: 1;
  transform: none;
}

[type=radio]:checked + label:before {
  border-color: #007bc4;
}

[type=radio]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

[type=radio]:not(:disabled):focus + label:before {
  border-color: #1d4259;
}

[type=radio]:not(:disabled):focus + label:after {
  background: #1d4259;
}

select,
.nice-select {
  width: 100%;
  height: 3.429rem;
  padding: 12px 18px;
  border: 1px solid #cce5f3;
  background-color: #fff;
  background: url(../../gfx/arrow-down-lb.svg) right center no-repeat;
  background-origin: content-box;
  color: #161616;
  cursor: pointer;
}
select:focus,
.nice-select:focus {
  outline: none;
  border: 1px solid #1d4259;
}

select::-ms-expand {
  display: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}
img:not(.img--fixed-width) {
  width: auto;
}

table {
  display: block;
  margin: 1em 0;
  border-collapse: collapse;
  overflow-x: auto;
}
table h1,
table h2,
table h3,
table h4 {
  margin: 0 0 0.3em;
}

td,
th {
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
}
td > *:last-child,
th > *:last-child {
  margin-bottom: 0;
}
td > *:last-child > *:last-child,
th > *:last-child > *:last-child {
  margin-bottom: 0;
}
td ul:only-child,
th ul:only-child {
  margin: 0;
}
td ul:first-child,
th ul:first-child {
  margin-top: 0;
}

thead td,
th {
  font-weight: 700;
}

/* abbr[title] {
    text-decoration-color: $grey;
} */
/*
    třídy, které jsou používány napříč webem
*/
.nod,
.sr-only,
hr {
  position: absolute;
  top: -99000px;
  left: -99000px;
}

.noscroll {
  overflow: hidden;
}

.svg-dummies {
  display: block;
  height: 0;
  overflow: hidden;
}

.blue {
  color: #007bc4;
}

.nero {
  color: #161616;
}

.highlight-gr {
  padding: 20px;
  background-color: #efefef;
}

.blue-box {
  background: #007bc4;
  color: #fff;
}
.blue-box a {
  color: #fff;
}

.outbound-link svg,
.new-window-link svg {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 4px;
  vertical-align: top;
  fill: #1d4259;
  stroke: #1d4259;
}
.outbound-link:hover svg, .outbound-link:focus svg,
.new-window-link:hover svg,
.new-window-link:focus svg {
  fill: #007bc4;
  stroke: #007bc4;
}

.flex {
  display: flex;
}
.flex--column-reverse {
  flex-direction: column-reverse;
}

h1 .listing-info {
  font-size: 1.571rem;
  font-weight: 700;
  white-space: nowrap;
}

h1 .section,
h2 .section {
  font-size: 18px;
  font-weight: 400;
}

.svg-ico {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
}

.but {
  display: block;
  height: 48px;
  padding: 0 18px;
  border: 1px solid #0b8ad5;
  font-size: 1rem;
  color: #fff;
  font-weight: 700;
  background-color: #0b8ad5;
}
.but:hover, .but:focus {
  border: 1px solid #1d4259;
  background-color: #fff;
  color: #1d4259;
  outline: none;
}
.but:active {
  border: 1px solid #1d4259;
  background-color: #1d4259;
  color: #fff;
}
.but + .but {
  margin-left: 20px;
}
.but--white {
  border: 1px solid #fff;
  background-color: #fff;
  color: #0b8ad5;
}
.but--c2a {
  border: 1px solid #f58221;
  background-color: #f58221;
  color: #fff;
}
.but--c2a.but--disabled, .but--c2a.but--disabled:hover, .but--c2a.but--disabled:focus, .but--c2a.but--disabled:active {
  border: 1px solid #f5c398;
  background-color: #f5c398;
  color: #fff;
  cursor: not-allowed;
}
.but--tercial {
  border: 1px solid #0b8ad5;
  background-color: #fff;
  color: #0b8ad5;
}
.but--tercial:hover, .but--tercial:focus {
  border: 1px solid #1d4259;
  color: #1d4259;
}
.but--tercial:active {
  border: 1px solid #1d4259;
  background-color: #1d4259;
  color: #fff;
}
.but--back, .but--back:hover, .but--back:focus, .but--back:active {
  border: 1px solid #fff;
  background-color: #fff;
  color: #1d4259;
}
.but--loadmore {
  border: 1px solid #fff;
  background-color: #fff;
  color: #1d4259;
}
.but--incontent {
  display: inline-block;
  text-decoration: none;
  line-height: 48px;
}

.optional,
.ares-lookup {
  float: right;
  font-size: 12px;
  line-height: 22px;
  color: #adadad;
}

.field-error {
  display: none;
  color: #d8000c;
}
.field-error.active {
  display: block;
}

[type=text].validated:invalid,
[type=password].validated:invalid,
[type=search].validated:invalid,
[type=email].validated:invalid,
[type=tel].validated:invalid,
select.validated:invalid,
textarea.validated:invalid {
  outline: none;
  border: 1px solid #d8000c;
}

.form-lang {
  display: none;
}

.picture-flex-base {
  display: flex;
  flex-direction: column;
}

.picturec,
.picturecb,
.picture,
.picturel,
.pictureb,
.picturelb,
.picturef,
.picturefb {
  display: table;
  border: 0;
  margin: 1em auto;
}
p > .picturec,
p > .picturecb,
p > .picture,
p > .picturel,
p > .pictureb,
p > .picturelb,
p > .picturef,
p > .picturefb {
  margin: 0.3em auto;
}

.picturec,
.picture,
.picturel,
.picturef {
  background-color: #efefef;
  padding: 10px;
}
.picturec-transparent,
.picture-transparent,
.picturel-transparent,
.picturef-transparent {
  background-color: transparent;
}

.picturecb,
.pictureb,
.picturelb,
.picturefb {
  background-color: transparent;
}

figure.picture-caption {
  margin: 1em auto;
  padding: 10px;
  text-align: center;
}
figure.picture-caption.picture-caption--p0 {
  padding: 0;
}
figure.picture-caption img {
  padding: 0;
}
figure.picture-caption figcaption {
  padding: 10px 0;
}
figure.picture-caption.picturec figcaption, figure.picture-caption.picture figcaption, figure.picture-caption.picturel figcaption, figure.picture-caption.picturef figcaption {
  padding-bottom: 0;
}

:root {
  --url-open-w: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47.5 47.5' fill='%23fff'%3E%3Cpath d='M47.5 47.5H0V0h47.5v47.5zM6 41.5h35.5V6H6v35.5z'/%3E%3Cpath d='M20.8 13.3h6v21h-6z'/%3E%3Cpath d='M13.3 20.8h21v6h-21z'/%3E%3C/svg%3E");
  --url-open-ab: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47.5 47.5' fill='%231d4259'%3E%3Cpath d='M47.5 47.5H0V0h47.5v47.5zM6 41.5h35.5V6H6v35.5z'/%3E%3Cpath d='M20.8 13.3h6v21h-6z'/%3E%3Cpath d='M13.3 20.8h21v6h-21z'/%3E%3C/svg%3E");
  --url-close-w: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47.5 47.5' fill='%23fff'%3E%3Cpath d='M47.5 47.5H0V0h47.5v47.5zM6 41.5h35.5V6H6v35.5z'/%3E%3Cpath d='M13.3 20.8h21v6h-21z'/%3E%3C/svg%3E");
  --url-close-b: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47.5 47.5' fill='%23007bc4'%3E%3Cpath d='M47.5 47.5H0V0h47.5v47.5zM6 41.5h35.5V6H6v35.5z'/%3E%3Cpath d='M13.3 20.8h21v6h-21z'/%3E%3C/svg%3E");
  --url-square-ab: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47.5 47.5' fill='%231d4259'%3E%3Cpath d='M47.5 47.5H0V0h47.5v47.5zM6 41.5h35.5V6H6v35.5z'/%3E%3C/svg%3E");
}

.expander {
  display: block;
  position: relative;
  padding: 18px 15px;
  border: 1px solid #dadada;
  background: #efefef;
  font-weight: 700;
  cursor: pointer;
}
.expander:hover, .expander:focus {
  background: #fff;
  color: #007bc4;
}
.expander:before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  width: 20px;
  height: 20px;
  margin: auto;
  background: url(../../gfx/arrow-down-b.svg) right center no-repeat;
  content: "";
}

.expander-close {
  display: none;
}
.is-visible .expander-close {
  position: absolute;
  top: 16px;
  right: 11px;
  display: block;
  width: 2.857rem;
  height: 2.857rem;
  overflow: hidden;
  font-size: 2.5rem;
  font-weight: 400;
  color: #1d4259;
  line-height: 2.857rem;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  content: "×";
}
.is-visible .expander-close:hover {
  color: #007bc4;
  text-decoration: none;
}

.more {
  display: table;
  margin: -20px 0 -10px;
  padding: 20px;
  background: url(../../gfx/next-b.svg) left center no-repeat;
  clear: both;
}
.more--bigger {
  margin-left: 20px;
  background-size: 6px 12px;
}
.more--inline-block {
  display: inline-block;
}

.cursor {
  cursor: pointer;
}

.split {
  padding: 0 0.286rem;
}

.nowrap {
  white-space: nowrap;
}

/* .available {
    color: $green-success;
} */
.first-uppercase,
.capitalize {
  text-transform: capitalize;
}

.form-hlaska {
  margin: 20px 0;
}
.form-hlaska + h1 {
  margin-top: 40px;
}

.report {
  display: block;
  padding: 15px;
  font-size: 1.143rem;
  background: #bfd8e6;
}
.report__note {
  display: block;
  font-size: 1rem;
  color: #1d4259;
}
.report.incontent {
  display: flow-root;
  margin: 20px 0;
  padding: 0 20px 9px 20px;
  font-size: 1rem;
}
.report.incontent .but,
.report .incontent .but {
  display: inline-block;
  text-decoration: none;
  line-height: 48px;
}
.report__secondary {
  display: block;
  margin: 1em 0 0 0;
  font-size: 0.857rem;
}

.success {
  background: #dff2bf;
}

.error {
  background: #ffbaba;
}

.warning {
  background: #cce5f3;
  color: #1d4259;
}
.warning h2 {
  color: #1d4259;
}

.notice {
  background: #fff;
  border: 1px solid #cce5f3;
  color: #383838;
}
.notice h2 {
  color: #383838;
}

.hint {
  background: #f5c398;
}

.urgent-box {
  background-color: #fefce0;
  border: 1px solid #fbf27d;
  margin: 0 0 27px 0;
  padding: 20px 10px;
}
.urgent-box h2,
.urgent-box p {
  margin: 0;
  padding: 0 10px;
  font-size: 1rem;
  line-height: 1.5714285714;
  color: #161616;
}

.clb {
  clear: both;
}

.cll {
  clear: left;
}

.clean {
  background: transparent;
  clear: both;
  height: 2px;
  font-size: 1px;
}

.centering {
  display: table;
  margin: auto;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.inline-block {
  display: inline-block;
}

.flow-root {
  display: flow-root;
}

.map-helper {
  position: relative;
  margin: 20px 0;
}

#map {
  width: 100%;
  height: 0;
  padding-bottom: 61.8034%;
}

.content-box {
  background-color: #efefef;
  display: flow-root;
  margin: 20px 0;
  padding: 0 20px;
}
.content-box h2,
.content-box h3,
.content-box h2 a,
.content-box h3 a {
  color: #161616;
}
.content-box .but {
  display: inline-block;
  margin-bottom: 9px;
  text-decoration: none;
  line-height: 48px;
}
.content-box .secondary-link {
  display: inline-block;
  line-height: 48px;
  height: 48px;
}
.content-box ul {
  padding-left: 8px;
  color: #161616;
}
.content-box ul li:before {
  background: #161616;
}

.table-wrapper {
  overflow-x: auto;
}
.table-wrapper--w600 table {
  min-width: 600px;
}

table.table-v0,
table.table-v0a,
table.table-v1,
table.table-v2,
table.table-v3,
table.table-v4,
table.table-v8,
table.table-v9,
table.table-v10,
table.table-v11 {
  display: table;
  width: 100%;
  border: 0;
  margin: 1.7em 0;
}
table.table-v0 tr,
table.table-v0a tr,
table.table-v1 tr,
table.table-v2 tr,
table.table-v3 tr,
table.table-v4 tr,
table.table-v8 tr,
table.table-v9 tr,
table.table-v10 tr,
table.table-v11 tr {
  border-bottom: 1px solid #cce5f3;
}
table.table-v0 tbody tr:hover,
table.table-v0a tbody tr:hover,
table.table-v1 tbody tr:hover,
table.table-v2 tbody tr:hover,
table.table-v3 tbody tr:hover,
table.table-v4 tbody tr:hover,
table.table-v8 tbody tr:hover,
table.table-v9 tbody tr:hover,
table.table-v10 tbody tr:hover,
table.table-v11 tbody tr:hover {
  background-color: #efefef;
}
table.table-v0 td,
table.table-v0 th,
table.table-v0a td,
table.table-v0a th,
table.table-v1 td,
table.table-v1 th,
table.table-v2 td,
table.table-v2 th,
table.table-v3 td,
table.table-v3 th,
table.table-v4 td,
table.table-v4 th,
table.table-v8 td,
table.table-v8 th,
table.table-v9 td,
table.table-v9 th,
table.table-v10 td,
table.table-v10 th,
table.table-v11 td,
table.table-v11 th {
  padding: 10px;
  text-align: left;
}
table.table-v0 td.right,
table.table-v0 th.right,
table.table-v0a td.right,
table.table-v0a th.right,
table.table-v1 td.right,
table.table-v1 th.right,
table.table-v2 td.right,
table.table-v2 th.right,
table.table-v3 td.right,
table.table-v3 th.right,
table.table-v4 td.right,
table.table-v4 th.right,
table.table-v8 td.right,
table.table-v8 th.right,
table.table-v9 td.right,
table.table-v9 th.right,
table.table-v10 td.right,
table.table-v10 th.right,
table.table-v11 td.right,
table.table-v11 th.right {
  text-align: right;
}
table.table-v0 th,
table.table-v0a th,
table.table-v1 th,
table.table-v2 th,
table.table-v3 th,
table.table-v4 th,
table.table-v8 th,
table.table-v9 th,
table.table-v10 th,
table.table-v11 th {
  font-size: 0.857rem;
  font-weight: 400;
}

table.table-vx {
  display: table;
  width: 100%;
  margin: 1.7em 0;
  border: 0;
}
table.table-vx tr {
  border-bottom: 0;
}
table.table-vx tbody tr:hover {
  background-color: transparent;
}
table.table-vx td,
table.table-vx th {
  padding: 10px;
  text-align: left;
  vertical-align: middle;
}
table.table-vx th {
  font-size: 0.857rem;
  font-weight: 400;
}
table.table-vx.table-vx--top td,
table.table-vx.table-vx--top th {
  vertical-align: top;
}
table.table-vx.table-vx--embed {
  margin: 0;
}
table.table-vx.table-vx--embed td,
table.table-vx.table-vx--embed th {
  padding: 0;
}
table.table-vx.table-vx--col1-w5em .col-1 {
  width: 5em;
}

table.table-v0.table-v0--top td,
table.table-v0.table-v0--top th {
  vertical-align: top;
}

table.table-v1 .col-1 {
  width: 10%;
}
table.table-v1 .col-2,
table.table-v1 .col-3,
table.table-v1 .col-4 {
  width: 30%;
}
table.table-v1.table-v1--top td,
table.table-v1.table-v1--top th {
  vertical-align: top;
}

table.table-v2 .col-1 {
  width: 50%;
}
table.table-v2 .col-2 {
  width: 50%;
}
table.table-v2.table-v2--top td,
table.table-v2.table-v2--top th {
  vertical-align: top;
}

table.table-v3 .col-1 {
  width: 50%;
}
table.table-v3 .col-2 {
  width: 25%;
}
table.table-v3 .col-3 {
  width: 25%;
}
table.table-v3 .job-title {
  display: block;
  font-size: 0.857rem;
  color: #007bc4;
}
table.table-v3 .job-title .dash {
  display: none;
}

.table-v3-helper ul {
  margin: -1.7em 0 1.7em 0;
}

table.table-v4 .col-1 {
  width: 33.3333333333%;
}
table.table-v4 .col-2 {
  width: 41.6666666667%;
}
table.table-v4 .col-3 {
  width: 25%;
}
table.table-v4 p {
  margin: 0;
}
table.table-v4.table-v4--top td,
table.table-v4.table-v4--top th {
  vertical-align: top;
}

table.table-v5 .col-1 {
  width: 25%;
}
table.table-v5 .col-2 {
  width: 18.75%;
}
table.table-v5 .col-3 {
  width: 18.75%;
}
table.table-v5 .col-4 {
  width: 18.75%;
}
table.table-v5 .col-5 {
  width: 18.75%;
}
table.table-v5.table-v5--top td,
table.table-v5.table-v5--top th {
  vertical-align: top;
}

table.table-v6 .col-1 {
  width: 80%;
}
table.table-v6 .col-2 {
  width: 20%;
}
table.table-v6.table-v6--top td,
table.table-v6.table-v6--top th {
  vertical-align: top;
}

table.table-v7 .col-1,
table.table-v7 .col-2,
table.table-v7 .col-3,
table.table-v7 .col-4,
table.table-v7 .col-5 {
  width: 16%;
}
table.table-v7 .col-6 {
  width: 20%;
}
table.table-v7.table-v7--top td,
table.table-v7.table-v7--top th {
  vertical-align: top;
}

table.table-v8 .col-1 {
  width: 37.5%;
}
table.table-v8 .col-2 {
  width: 17.5%;
}
table.table-v8 .col-3,
table.table-v8 .col-4 {
  width: 12.5%;
}
table.table-v8 .col-5 {
  width: 20%;
  min-width: 100px;
}
table.table-v8.table-v8--top td,
table.table-v8.table-v8--top th {
  vertical-align: top;
}

table.table-v9 .col-1 {
  width: 20%;
}
table.table-v9 .col-2 {
  width: 50%;
}
table.table-v9 .col-3 {
  width: 30%;
}
table.table-v9.table-v9--top td,
table.table-v9.table-v9--top th {
  vertical-align: top;
}

table.table-v10 .col-1 {
  width: 60%;
}
table.table-v10 .col-2 {
  width: 20%;
}
table.table-v10 .col-3 {
  width: 20%;
  min-width: 150px;
}
table.table-v10.table-v10--top td,
table.table-v10.table-v10--top th {
  vertical-align: top;
}

table.table-v11 th {
  width: 25%;
}
table.table-v11.table-v11--top td,
table.table-v11.table-v11--top th {
  vertical-align: top;
}

.tiles {
  position: relative;
  zoom: 1;
}
.tiles:after {
  content: "";
  display: table;
  clear: both;
}

.tile {
  background-color: #efefef;
  position: relative;
  display: flex;
  margin: 0 0 20px 0;
  padding: 20px;
}
.tile.tile-blank {
  position: absolute;
  top: -99999999;
}
.tiles-guide .tile {
  background-color: transparent;
  padding: 0;
}
.tiles-eshop .tile {
  background-color: transparent;
  padding: 0;
}

.tile__img {
  flex: 1;
}

.tile__txt {
  position: relative;
  flex: 2;
  font-size: 1.143rem;
  font-weight: 700;
}
.tiles-guide .tile__txt {
  font-size: 1rem;
  font-weight: 400;
}
.tile__txt a,
.tile__txt .txt {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(0, -50%);
}

.ol--profile-img {
  list-style: none;
  counter-reset: profile-img-legend-counter;
  padding-left: 0;
}
.ol--profile-img li {
  counter-increment: profile-img-legend-counter;
  position: relative;
  display: flow-root;
  padding-left: 28px;
}
.ol--profile-img li::before {
  content: counter(profile-img-legend-counter);
  position: absolute;
  left: 0;
  top: 0;
  background-color: #00adee;
  width: 1.9em;
  height: 1.9em;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 2px -1px #000;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 1.5;
}

.ytframe-helper,
.c2 {
  position: relative;
  width: 100%;
  height: 0;
  margin-bottom: 15px;
  padding-bottom: 56.25%;
}

.ytframe,
.c2 iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.mathjax {
  opacity: 0;
  transition: all 0.5s;
}
.mathjax--box {
  display: block;
  min-height: 41px;
  margin: 1em 0;
}

/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 500px) {
  .picture-flex-base {
    flex-direction: row;
  }
  .picture,
  .picture.picture-caption,
  .pictureb,
  .pictureb.picture-caption {
    float: right;
    max-width: 50%;
    margin: 0.32em 0 1em 20px;
  }
  p > .picture,
  p > .picture.picture-caption,
  p > .pictureb,
  p > .pictureb.picture-caption {
    margin: 0.32em 0 1em 20px;
  }
  .brand-profile .picture,
  .brand-profile .picture.picture-caption,
  .brand-profile .pictureb,
  .brand-profile .pictureb.picture-caption {
    padding: 0;
  }
  .picturel,
  .picturel.picture-caption,
  .picturelb,
  .picturelb.picture-caption {
    float: left;
    max-width: 50%;
    margin: 0.32em 20px 1em 0;
  }
  p > .picturel,
  p > .picturel.picture-caption,
  p > .picturelb,
  p > .picturelb.picture-caption {
    margin: 0.32em 20px 1em 0;
  }
  .brand-profile .picturel,
  .brand-profile .picturel.picture-caption,
  .brand-profile .picturelb,
  .brand-profile .picturelb.picture-caption {
    padding: 0;
  }
  .flex--mobile-only {
    display: block;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 600px) {
  .urgent-box {
    display: flex;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 700px) {
  .half {
    float: left;
    width: calc((100% - 24px) / 2);
    margin-right: 24px;
  }
  .half + .half {
    margin-right: 0;
  }
  .half-left {
    float: left;
    width: calc(50% - 10px);
  }
  .half-right {
    float: right;
    width: calc(50% - 10px);
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media (max-width: 1199.9375px) {
  .table-v0a-helper {
    /* Table rows have a gray bottom stroke by default */
  }
  .table-v0a-helper .tablesaw-stack tbody tr {
    display: block;
    width: 100%;
    border: 1px solid #efefef;
    margin: 0 0 20px 0;
  }
  .table-v0a-helper .tablesaw-stack thead td,
  .table-v0a-helper .tablesaw-stack thead th {
    display: none;
  }
  .table-v0a-helper .tablesaw-stack tbody td,
  .table-v0a-helper .tablesaw-stack tbody th {
    display: block;
    width: 100%;
  }
  .table-v0a-helper .tablesaw-cell-label {
    vertical-align: top;
  }
  .table-v0a-helper .tablesaw-cell-content {
    max-width: 67%;
    display: inline-block;
  }
  .table-v0a-helper .tablesaw-stack td:empty,
  .table-v0a-helper .tablesaw-stack th:empty {
    display: none;
  }
}
@media (min-width: 1200px) {
  .table-v0a-helper {
    /* Show the table header rows */
    /* Hide the labels in each cell */
  }
  .table-v0a-helper .tablesaw-stack tr {
    display: table-row;
  }
  .table-v0a-helper .tablesaw-stack td,
  .table-v0a-helper .tablesaw-stack th,
  .table-v0a-helper .tablesaw-stack thead td,
  .table-v0a-helper .tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }
  .table-v0a-helper .tablesaw-stack td .tablesaw-cell-label,
  .table-v0a-helper .tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
}

@media screen and (max-width: 1199px) {
  table.table-v0a tr {
    border: 0;
  }
  table.table-v0a b.tablesaw-cell-label {
    font-weight: 400;
    text-align: right;
  }
}
@media screen and (min-width: 1200px) {
  table.table-v0a {
    margin-top: 0;
    margin-bottom: 3.4em;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media (max-width: 519.9375px) {
  .table-v2-helper {
    /* Table rows have a gray bottom stroke by default */
  }
  .table-v2-helper .tablesaw-stack tbody tr {
    display: block;
    width: 100%;
    border: 1px solid #efefef;
    margin: 0 0 20px 0;
  }
  .table-v2-helper .tablesaw-stack thead td,
  .table-v2-helper .tablesaw-stack thead th {
    display: none;
  }
  .table-v2-helper .tablesaw-stack tbody td,
  .table-v2-helper .tablesaw-stack tbody th {
    display: block;
    width: 100%;
  }
  .table-v2-helper .tablesaw-cell-label {
    vertical-align: top;
  }
  .table-v2-helper .tablesaw-cell-content {
    max-width: 67%;
    display: inline-block;
  }
  .table-v2-helper .tablesaw-stack td:empty,
  .table-v2-helper .tablesaw-stack th:empty {
    display: none;
  }
}
@media (min-width: 520px) {
  .table-v2-helper {
    /* Show the table header rows */
    /* Hide the labels in each cell */
  }
  .table-v2-helper .tablesaw-stack tr {
    display: table-row;
  }
  .table-v2-helper .tablesaw-stack td,
  .table-v2-helper .tablesaw-stack th,
  .table-v2-helper .tablesaw-stack thead td,
  .table-v2-helper .tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }
  .table-v2-helper .tablesaw-stack td .tablesaw-cell-label,
  .table-v2-helper .tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
}

@media screen and (max-width: 519px) {
  table.table-v2 tr {
    border: 0;
  }
  table.table-v2 b.tablesaw-cell-label {
    font-weight: 700;
    text-align: right;
  }
}
@media screen and (min-width: 520px) {
  table.table-v2 {
    margin-top: 0;
    margin-bottom: 3.4em;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media (max-width: 519.9375px) {
  .table-v0-helper {
    /* Table rows have a gray bottom stroke by default */
  }
  .table-v0-helper .tablesaw-stack tbody tr {
    display: block;
    width: 100%;
    border: 1px solid #efefef;
    margin: 0 0 20px 0;
  }
  .table-v0-helper .tablesaw-stack thead td,
  .table-v0-helper .tablesaw-stack thead th {
    display: none;
  }
  .table-v0-helper .tablesaw-stack tbody td,
  .table-v0-helper .tablesaw-stack tbody th {
    display: block;
    width: 100%;
  }
  .table-v0-helper .tablesaw-cell-label {
    vertical-align: top;
  }
  .table-v0-helper .tablesaw-cell-content {
    max-width: 67%;
    display: inline-block;
  }
  .table-v0-helper .tablesaw-stack td:empty,
  .table-v0-helper .tablesaw-stack th:empty {
    display: none;
  }
}
@media (min-width: 520px) {
  .table-v0-helper {
    /* Show the table header rows */
    /* Hide the labels in each cell */
  }
  .table-v0-helper .tablesaw-stack tr {
    display: table-row;
  }
  .table-v0-helper .tablesaw-stack td,
  .table-v0-helper .tablesaw-stack th,
  .table-v0-helper .tablesaw-stack thead td,
  .table-v0-helper .tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }
  .table-v0-helper .tablesaw-stack td .tablesaw-cell-label,
  .table-v0-helper .tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
}

@media screen and (max-width: 519px) {
  table.table-v0 tr {
    border: 0;
  }
  table.table-v0 b.tablesaw-cell-label {
    width: 50%;
    font-weight: 400;
  }
  table.table-v0 .mob-nod {
    display: none;
  }
  table.table-v0 .mob-append:after {
    content: ":";
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media (max-width: 519.9375px) {
  .table-v3-helper {
    /* Table rows have a gray bottom stroke by default */
  }
  .table-v3-helper .tablesaw-stack tbody tr {
    display: block;
    width: 100%;
    border: 1px solid #efefef;
    margin: 0 0 20px 0;
  }
  .table-v3-helper .tablesaw-stack thead td,
  .table-v3-helper .tablesaw-stack thead th {
    display: none;
  }
  .table-v3-helper .tablesaw-stack tbody td,
  .table-v3-helper .tablesaw-stack tbody th {
    display: block;
    width: 100%;
  }
  .table-v3-helper .tablesaw-cell-label {
    vertical-align: top;
  }
  .table-v3-helper .tablesaw-cell-content {
    max-width: 67%;
    display: inline-block;
  }
  .table-v3-helper .tablesaw-stack td:empty,
  .table-v3-helper .tablesaw-stack th:empty {
    display: none;
  }
}
@media (min-width: 520px) {
  .table-v3-helper {
    /* Show the table header rows */
    /* Hide the labels in each cell */
  }
  .table-v3-helper .tablesaw-stack tr {
    display: table-row;
  }
  .table-v3-helper .tablesaw-stack td,
  .table-v3-helper .tablesaw-stack th,
  .table-v3-helper .tablesaw-stack thead td,
  .table-v3-helper .tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }
  .table-v3-helper .tablesaw-stack td .tablesaw-cell-label,
  .table-v3-helper .tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
}

@media screen and (max-width: 519px) {
  table.table-v3 tr {
    border: 0;
  }
  table.table-v3 b.tablesaw-cell-label {
    font-weight: 400;
    text-align: right;
  }
}
@media screen and (min-width: 520px) {
  h2.allcontacts {
    margin-bottom: 0;
  }
  table.table-v3 {
    margin-top: 0;
    margin-bottom: 3.4em;
  }
  .table-v3-helper ul {
    margin-bottom: 3.4em;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media (max-width: 519.9375px) {
  .table-v5-helper {
    /* Table rows have a gray bottom stroke by default */
  }
  .table-v5-helper .tablesaw-stack tbody tr {
    display: block;
    width: 100%;
    border: 1px solid #efefef;
    margin: 0 0 20px 0;
  }
  .table-v5-helper .tablesaw-stack thead td,
  .table-v5-helper .tablesaw-stack thead th {
    display: none;
  }
  .table-v5-helper .tablesaw-stack tbody td,
  .table-v5-helper .tablesaw-stack tbody th {
    display: block;
    width: 100%;
  }
  .table-v5-helper .tablesaw-cell-label {
    vertical-align: top;
  }
  .table-v5-helper .tablesaw-cell-content {
    max-width: 67%;
    display: inline-block;
  }
  .table-v5-helper .tablesaw-stack td:empty,
  .table-v5-helper .tablesaw-stack th:empty {
    display: none;
  }
}
@media (min-width: 520px) {
  .table-v5-helper {
    /* Show the table header rows */
    /* Hide the labels in each cell */
  }
  .table-v5-helper .tablesaw-stack tr {
    display: table-row;
  }
  .table-v5-helper .tablesaw-stack td,
  .table-v5-helper .tablesaw-stack th,
  .table-v5-helper .tablesaw-stack thead td,
  .table-v5-helper .tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }
  .table-v5-helper .tablesaw-stack td .tablesaw-cell-label,
  .table-v5-helper .tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
}

@media screen and (max-width: 519px) {
  table.table-v5 tr {
    border: 0;
  }
  table.table-v5 b.tablesaw-cell-label {
    width: 50%;
    font-weight: 400;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (max-width: 519px) {
  table.table-v6 tr {
    border: 0;
  }
  table.table-v6 .col-1 {
    width: 66.6666666667%;
  }
  table.table-v6 .col-2 {
    width: 33.3333333333%;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media (max-width: 519.9375px) {
  .table-v7-helper {
    /* Table rows have a gray bottom stroke by default */
  }
  .table-v7-helper .tablesaw-stack tbody tr {
    display: block;
    width: 100%;
    border: 1px solid #efefef;
    margin: 0 0 20px 0;
  }
  .table-v7-helper .tablesaw-stack thead td,
  .table-v7-helper .tablesaw-stack thead th {
    display: none;
  }
  .table-v7-helper .tablesaw-stack tbody td,
  .table-v7-helper .tablesaw-stack tbody th {
    display: block;
    width: 100%;
  }
  .table-v7-helper .tablesaw-cell-label {
    vertical-align: top;
  }
  .table-v7-helper .tablesaw-cell-content {
    max-width: 67%;
    display: inline-block;
  }
  .table-v7-helper .tablesaw-stack td:empty,
  .table-v7-helper .tablesaw-stack th:empty {
    display: none;
  }
}
@media (min-width: 520px) {
  .table-v7-helper {
    /* Show the table header rows */
    /* Hide the labels in each cell */
  }
  .table-v7-helper .tablesaw-stack tr {
    display: table-row;
  }
  .table-v7-helper .tablesaw-stack td,
  .table-v7-helper .tablesaw-stack th,
  .table-v7-helper .tablesaw-stack thead td,
  .table-v7-helper .tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }
  .table-v7-helper .tablesaw-stack td .tablesaw-cell-label,
  .table-v7-helper .tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
}

@media screen and (max-width: 519px) {
  table.table-v7 tr {
    border: 0;
  }
  table.table-v7 b.tablesaw-cell-label {
    width: 50%;
    font-weight: 400;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media (max-width: 559.9375px) {
  .table-v8-helper {
    /* Table rows have a gray bottom stroke by default */
  }
  .table-v8-helper .tablesaw-stack tbody tr {
    display: block;
    width: 100%;
    border: 1px solid #efefef;
    margin: 0 0 20px 0;
  }
  .table-v8-helper .tablesaw-stack thead td,
  .table-v8-helper .tablesaw-stack thead th {
    display: none;
  }
  .table-v8-helper .tablesaw-stack tbody td,
  .table-v8-helper .tablesaw-stack tbody th {
    display: block;
    width: 100%;
  }
  .table-v8-helper .tablesaw-cell-label {
    vertical-align: top;
  }
  .table-v8-helper .tablesaw-cell-content {
    max-width: 67%;
    display: inline-block;
  }
  .table-v8-helper .tablesaw-stack td:empty,
  .table-v8-helper .tablesaw-stack th:empty {
    display: none;
  }
}
@media (min-width: 560px) {
  .table-v8-helper {
    /* Show the table header rows */
    /* Hide the labels in each cell */
  }
  .table-v8-helper .tablesaw-stack tr {
    display: table-row;
  }
  .table-v8-helper .tablesaw-stack td,
  .table-v8-helper .tablesaw-stack th,
  .table-v8-helper .tablesaw-stack thead td,
  .table-v8-helper .tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }
  .table-v8-helper .tablesaw-stack td .tablesaw-cell-label,
  .table-v8-helper .tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
}

@media screen and (max-width: 559px) {
  table.table-v8 tr {
    border: 0;
  }
  table.table-v8 b.tablesaw-cell-label {
    width: 50%;
    font-weight: 400;
  }
  table.table-v8 b.tablesaw-cell-label .hide-label {
    display: none;
  }
  table.table-v8 .tablesaw-cell-content {
    max-width: 50%;
  }
  table.table-v8 .col-1 .tablesaw-cell-content {
    min-width: 250px;
    max-width: 100%;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media (max-width: 519.9375px) {
  .table-v9-helper {
    /* Table rows have a gray bottom stroke by default */
  }
  .table-v9-helper .tablesaw-stack tbody tr {
    display: block;
    width: 100%;
    border: 1px solid #efefef;
    margin: 0 0 20px 0;
  }
  .table-v9-helper .tablesaw-stack thead td,
  .table-v9-helper .tablesaw-stack thead th {
    display: none;
  }
  .table-v9-helper .tablesaw-stack tbody td,
  .table-v9-helper .tablesaw-stack tbody th {
    display: block;
    width: 100%;
  }
  .table-v9-helper .tablesaw-cell-label {
    vertical-align: top;
  }
  .table-v9-helper .tablesaw-cell-content {
    max-width: 67%;
    display: inline-block;
  }
  .table-v9-helper .tablesaw-stack td:empty,
  .table-v9-helper .tablesaw-stack th:empty {
    display: none;
  }
}
@media (min-width: 520px) {
  .table-v9-helper {
    /* Show the table header rows */
    /* Hide the labels in each cell */
  }
  .table-v9-helper .tablesaw-stack tr {
    display: table-row;
  }
  .table-v9-helper .tablesaw-stack td,
  .table-v9-helper .tablesaw-stack th,
  .table-v9-helper .tablesaw-stack thead td,
  .table-v9-helper .tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }
  .table-v9-helper .tablesaw-stack td .tablesaw-cell-label,
  .table-v9-helper .tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
}

@media screen and (max-width: 519px) {
  table.table-v9 tr {
    border: 0;
  }
  table.table-v9 b.tablesaw-cell-label {
    display: none;
  }
  table.table-v9 .tablesaw-cell-content {
    width: 100%;
    max-width: 100%;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media (max-width: 519.9375px) {
  .table-v10-helper {
    /* Table rows have a gray bottom stroke by default */
  }
  .table-v10-helper .tablesaw-stack tbody tr {
    display: block;
    width: 100%;
    border: 1px solid #efefef;
    margin: 0 0 20px 0;
  }
  .table-v10-helper .tablesaw-stack thead td,
  .table-v10-helper .tablesaw-stack thead th {
    display: none;
  }
  .table-v10-helper .tablesaw-stack tbody td,
  .table-v10-helper .tablesaw-stack tbody th {
    display: block;
    width: 100%;
  }
  .table-v10-helper .tablesaw-cell-label {
    vertical-align: top;
  }
  .table-v10-helper .tablesaw-cell-content {
    max-width: 67%;
    display: inline-block;
  }
  .table-v10-helper .tablesaw-stack td:empty,
  .table-v10-helper .tablesaw-stack th:empty {
    display: none;
  }
}
@media (min-width: 520px) {
  .table-v10-helper {
    /* Show the table header rows */
    /* Hide the labels in each cell */
  }
  .table-v10-helper .tablesaw-stack tr {
    display: table-row;
  }
  .table-v10-helper .tablesaw-stack td,
  .table-v10-helper .tablesaw-stack th,
  .table-v10-helper .tablesaw-stack thead td,
  .table-v10-helper .tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }
  .table-v10-helper .tablesaw-stack td .tablesaw-cell-label,
  .table-v10-helper .tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
}

@media screen and (max-width: 519px) {
  table.table-v10 tr {
    border: 0;
  }
  table.table-v10 b.tablesaw-cell-label {
    display: none;
  }
  table.table-v10 .tablesaw-cell-content {
    width: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 480px) {
  .tiles {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
    margin: 0 0 20px 0;
  }
}
@media screen and (min-width: 480px) and (-ms-high-contrast: active), screen and (min-width: 480px) and (-ms-high-contrast: none) {
  .tiles {
    display: block;
    margin: 0 -1px 0 -20px;
  }
}
@media screen and (min-width: 480px) {
  .tile {
    display: block;
    margin: 0;
    text-align: center;
  }
  .tile.tile-blank {
    position: relative;
  }
  .tile a,
  .tile .txt {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 100%;
  }
  .tiles-guide .tile a, .tiles-eshop .tile a,
  .tiles-guide .tile .txt,
  .tiles-eshop .tile .txt {
    position: static;
    transform: none;
  }
}
@media screen and (min-width: 480px) and (-ms-high-contrast: active), screen and (min-width: 480px) and (-ms-high-contrast: none) {
  .tile {
    display: block;
    float: left;
    width: calc((100% - 60px) / 3);
    margin: 0 0 20px 20px;
  }
  .tiles-guide .tile, .tiles-eshop .tile {
    height: 0;
    padding-bottom: calc((100% - 60px) / 3);
  }
}
@media screen and (min-width: 480px) {
  .tile__img {
    position: relative;
    height: 0;
    padding-bottom: 44.4444444444%;
  }
  .tiles-square .tile__img {
    padding-bottom: 66.6666666667%;
  }
  .tiles-guide .tile__img, .tiles-eshop .tile__img {
    height: auto;
    margin-bottom: 10px;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 480px) {
  .tile__txt {
    padding-bottom: 22.2222222222%;
    height: 0;
    font-size: 1rem;
  }
  .tiles-square .tile__txt {
    padding-bottom: 33.3333333333%;
  }
  .tiles-guide .tile__txt, .tiles-eshop .tile__txt {
    height: auto;
    padding-bottom: 0;
  }
}
/*
    layout stránky
*/
.wrap {
  max-width: 1170px;
  margin: 0 20px;
}

.header {
  position: relative;
  margin-bottom: 94px;
}
.module-function-basket .header, .module-function-order .header {
  margin-bottom: 0;
}

.page-content {
  position: relative;
  margin-top: 7px;
}

.hp-tabs-menu {
  margin-bottom: 35px;
}

.info__about-us {
  margin-bottom: 40px;
}

.info__news {
  margin-bottom: 10px;
}

.footer {
  content-visibility: auto;
  contain-intristic-size: 100% 834px;
  margin-top: 45px;
}

.container {
  position: relative;
}

.main {
  position: relative;
  margin-top: -28px;
  margin-bottom: 45px;
  background: #fff;
}

.sidebar {
  position: relative;
  clear: both;
}

.header-banner {
  display: none;
}

.eshop-submenu-box {
  display: none;
  content-visibility: hidden;
}

/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 500px) {
  .wrap {
    margin-left: 20px;
    margin-right: 20px;
  }
  .hp-tabs-menu {
    margin-bottom: 26px;
  }
  .footer {
    contain-intristic-size: 100% 769px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 650px) {
  .footer {
    contain-intristic-size: 100% 532px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 800px) {
  .footer {
    contain-intristic-size: 100% 464px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 900px) {
  .main {
    float: right;
    width: 65%;
    min-height: 300px;
    margin-top: 9.1211vw;
    margin-bottom: 0;
    padding-left: 40px;
    padding-top: 1px;
  }
  .main:before {
    position: absolute;
    display: block;
    width: 10px;
    height: 8.4vw;
    top: 0;
    left: -10px;
    background-color: #007bc4;
    content: "";
  }
  .sidebar {
    float: left;
    width: 35%;
    max-width: 350px;
    margin-top: calc(23.6666666667vw + 26px);
    clear: none;
  }
  .header-banner {
    display: block;
    position: absolute;
    width: 100%;
    height: 23.667vw;
    top: 0;
    z-index: -1;
    overflow: hidden;
  }
  .eshop-submenu-box {
    display: block;
    content-visibility: visible;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 1000px) {
  .main {
    width: calc(100% - 350px);
  }
  .sidebar {
    width: 350px;
  }
  .footer {
    contain-intristic-size: 100% 388px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 1200px) {
  .wrap {
    margin-left: auto;
    margin-right: auto;
  }
  .main {
    margin-top: 110px;
  }
  .main:before {
    height: 101px;
  }
  .sidebar {
    margin-top: 310px;
  }
  .header-banner {
    height: 284px;
  }
}
/*
    horní pruh hlavičky
*/
.header-top {
  line-height: 51px;
  margin: 0 -20px;
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
}
.header-top a {
  color: #fff;
  text-decoration: none;
}
.header-top a:hover, .header-top a:focus {
  text-decoration: underline;
}
.header-top label:not(.field-error) {
  color: #161616;
}
.header-top .form-row-psw label {
  color: #adadad;
}

.dummy-section {
  flex: 1;
}

.user-section {
  flex: 1;
  max-width: 45%;
  padding-right: 10px;
}
.user-section a {
  display: inline-block;
  vertical-align: bottom;
}

.login-link {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.login-box,
.logged-box {
  display: none;
}

.header-login.is-visible .login-box {
  display: block;
  position: absolute;
  top: 51px;
  left: -20px;
  width: 100vw;
  min-width: 300px;
  padding: 30px 30px 35px;
  border: 1px solid #efefef;
  background: #fff;
  line-height: 1;
  z-index: 1000;
  box-shadow: rgba(22, 22, 22, 0.5) 0 0 20px;
}
.header-login.is-visible .login-box h3 {
  margin: 15px 0 20px;
  color: #007bc4;
}
.header-login.is-visible .login-box .form-row {
  width: auto;
  margin: 0 0 15px 0;
}
.header-login.is-visible .login-box .field-error {
  line-height: 1.5714285714;
}
.header-login.is-visible .login-box .but {
  width: 100%;
  margin: 0 0 20px;
}
.header-login.is-visible .login-box .line a {
  color: #1d4259;
  text-decoration: underline;
}
.header-login.is-visible .login-box .line a:hover, .header-login.is-visible .login-box .line a:focus {
  color: #007bc4;
  text-decoration: none;
}
.header-login.is-visible .login-box ul {
  margin: 0;
  padding: 0;
}
.header-login.is-visible .login-box ul li {
  margin: 1.5em 0 0;
  padding: 0;
}
.header-login.is-visible .login-box ul li:before {
  display: none;
}
.header-login.is-visible .login-box .expander-close {
  top: 8px;
  right: 8px;
  color: #1d4259;
  text-decoration: none;
}
.header-login.is-visible .login-box .expander-close:hover, .header-login.is-visible .login-box .expander-close:focus {
  color: #007bc4;
}

.header-logged {
  position: relative;
}
.header-logged a {
  text-decoration: none;
}
.header-logged a:hover {
  text-decoration: underline;
}

.header-login,
.logged {
  overflow: hidden;
  white-space: nowrap;
}

.login-links a {
  display: none;
}

.header-logged.is-visible .expander-close {
  top: 8px;
  right: 8px;
  color: #161616;
  z-index: 2000;
}
.header-logged.is-visible .expander-close:hover, .header-logged.is-visible .expander-close:focus {
  color: #007bc4;
  text-decoration: none;
}
.header-logged.is-visible ul {
  margin: 0;
  padding: 0;
}
.header-logged.is-visible li,
.header-logged.is-visible .logout-link {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #cce5f3;
  font-size: 1.143rem;
  line-height: 1;
}
.header-logged.is-visible .logout-link {
  display: block;
  top: 235px;
  left: 11px;
  width: calc(100vw + -62px);
  padding: 16px 20px 16px 25px;
  position: absolute;
  color: #1d4259;
  background: url(../../gfx/next-b.svg) left center/9px 13px no-repeat;
  z-index: 1500;
}
.header-logged.is-visible .logout-link:hover, .header-logged.is-visible .logout-link:focus {
  color: #007bc4;
}
.header-logged.is-visible .logged-box {
  display: block;
  position: absolute;
  top: 51px;
  left: -20px;
  width: 100vw;
  min-width: 300px;
  padding: 36px 30px 79px;
  border: 1px solid #efefef;
  background: #fff;
  z-index: 1000;
  box-shadow: rgba(22, 22, 22, 0.5) 0 0 20px;
}
.header-logged.is-visible .logged-box li a {
  display: block;
  padding: 16px 20px 16px 25px;
  color: #1d4259;
  background: url(../../gfx/next-b.svg) left center/9px 13px no-repeat;
}
.header-logged.is-visible .logged-box li a:hover, .header-logged.is-visible .logged-box li a:focus {
  color: #007bc4;
}

.lang-section,
.currency-section {
  flex: 1;
  color: #fff;
  text-align: right;
}
.lang-section a,
.currency-section a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
}
.lang-section a:hover, .lang-section a:focus,
.currency-section a:hover,
.currency-section a:focus {
  text-decoration: underline;
}

.lang_item .full {
  display: none;
}

span.lang_item,
span.currency_item {
  font-weight: 700;
}

/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 400px) {
  .login-link,
  .lang-section,
  .currency-section {
    font-size: 1.143rem;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 500px) {
  .header-login.is-visible .login-box,
  .header-logged.is-visible .logged-box {
    width: 370px;
    left: 0;
  }
  .header-logged.is-visible .logout-link {
    left: 31px;
    width: 308px;
  }
  .lang_item .full {
    display: inline;
  }
  .lang_item .short {
    display: none;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 600px) {
  .header-top {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    background: transparent;
    color: #161616;
    line-height: 36px;
    z-index: 20;
  }
  .header-top a {
    color: #161616;
  }
  .user-section {
    position: absolute;
    right: 0;
    top: 27px;
    text-align: right;
    max-width: none;
    padding-right: 0;
  }
  .login-link {
    font-size: 1rem;
  }
  .lang-section {
    position: absolute;
    width: auto;
    top: 56px;
    right: 0;
    color: #007bc4;
    font-size: 0.857rem;
  }
  .lang-section a,
  .lang-section .lang_item {
    color: #161616;
  }
  .currency-section {
    position: absolute;
    width: auto;
    top: 56px;
    right: 120px;
    color: #007bc4;
    font-size: 0.857rem;
  }
  .currency-section a,
  .currency-section .currency_item {
    color: #161616;
  }
  .header-login.is-visible .login-box,
  .header-logged.is-visible .logged-box {
    top: 38px;
    right: 0;
    left: auto;
    text-align: left;
  }
  .header-logged.is-visible .logout-link {
    left: auto;
    right: 31px;
    top: 222px;
    text-align: left;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 750px) {
  .user-section {
    top: 0;
  }
  .lang-section,
  .currency-section {
    top: 62px;
    left: 0;
    right: auto;
    margin-left: 20vw;
    text-align: left;
  }
  .currency-section {
    left: 120px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 775px) {
  .lang-section,
  .currency-section {
    margin-left: 18vw;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 800px) {
  .lang-section,
  .currency-section {
    margin-left: 20vw;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 950px) {
  .lang-section,
  .currency-section {
    margin-left: 18vw;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 1200px) {
  .lang-section,
  .currency-section {
    margin-left: 190px;
  }
}
/*
    spodní pruh hlavičky
*/
.header-bottom {
  position: relative;
  background: #fff;
}

.logo-section {
  float: left;
  width: 35%;
  padding: calc(-1.4285714286vw + 22.5714285714px) 0;
}
.logo-section svg {
  max-width: 100%;
  max-height: 100%;
  vertical-align: top;
  fill: #007bc4;
}

.header-middle__container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 42%;
}

.moto {
  display: inline-block;
  font-size: calc(1.4285714286vw + 15.4285714286px);
  line-height: 1.2;
  color: #007bc4;
  vertical-align: middle;
}

.tree-section {
  position: absolute;
  left: 0;
  top: calc(24vw + 45.2px);
  width: 48px;
  height: 48px;
  z-index: 1;
  border: 1px solid #dadada;
}
.tree-section .svg-ico {
  fill: #1d4259;
  width: 32px;
  height: 31px;
  margin-left: -1px;
  margin-top: 1px;
}

.basket-section {
  position: absolute;
  left: 136px;
  top: calc(24vw + 45.2px);
  width: 48px;
  height: 48px;
  z-index: 1;
}

.basket-empty,
.basket-full {
  position: absolute;
  width: 48px;
  height: 48px;
  border: 1px solid #dadada;
}
.basket-empty .nip,
.basket-full .nip {
  display: none;
}
.basket-empty .svg-ico,
.basket-full .svg-ico {
  fill: #1d4259;
  width: 32px;
  height: 31px;
  margin-left: -1px;
  margin-top: 1px;
}

.basket-full {
  display: block;
  text-align: center;
  text-decoration: none;
}
.basket-full:hover {
  border: 1px solid #1d4259;
}
.basket-full.highlight {
  outline: 0 !important;
}
.basket-full.highlight .basket-ico {
  outline: 5px solid #f5c398 !important;
  outline-offset: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.basket__count {
  position: absolute;
  top: -9px;
  right: -9px;
  width: 21px;
  height: 21px;
  font-size: 1rem;
  font-weight: 700;
  background: #f58221;
  border-radius: 50%;
  color: #fff;
  transition: top 300ms, right 300ms;
}

/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 370px) {
  .tree-section,
  .basket-section {
    top: calc(-3.056768559vw + 145.3100436681px);
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 600px) {
  .logo-section {
    width: 120px;
    padding: 14px 0;
  }
  .header-middle__container {
    margin-left: 22vw;
  }
  .moto {
    padding-left: 0;
    font-size: 22px;
  }
  .tree-section,
  .basket-section {
    top: 127px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 750px) {
  .header-middle__container {
    top: 42%;
    margin-left: 20vw;
  }
  .moto {
    padding-left: 0;
  }
  .moto br {
    display: none;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 775px) {
  .header-middle__container {
    margin-left: 18vw;
  }
  .basket-section {
    top: 46px;
    left: auto;
    right: 0;
    width: auto;
  }
  .basket-empty,
  .basket-full {
    background: #fff;
    position: static;
    display: block;
    width: auto;
    height: 48px;
    border: 1px solid #cce5f3;
    padding: 12px 18px 12px 16px;
    color: #1d4259;
  }
  .basket-empty .nip,
  .basket-full .nip {
    display: inline-block;
    vertical-align: middle;
  }
  .basket-ico {
    position: relative;
    display: inline-block;
    width: 26px;
    margin-right: 10px;
  }
  .basket-ico .svg-ico {
    fill: #1d4259;
    width: 26px;
    height: 24px;
    margin-left: 0;
    margin-top: -4px;
  }
  .basket-full .basket-ico + .nip {
    display: none;
  }
  .basket-full .basket__count {
    font-size: 15px;
  }
  .basket-full:hover {
    background: #f58221;
    border: 1px solid #f58221;
    color: #fff;
  }
  .basket-full:hover .svg-ico {
    fill: #fff;
  }
  .basket-full:hover .basket__count {
    color: #fff;
  }
  .basket-full.highlight {
    outline: 5px solid #f5c398 !important;
    outline-offset: 0;
  }
  .basket-full.highlight .basket-ico {
    outline: 0 !important;
    width: 26px;
    height: auto;
  }
  .basket__count {
    display: inline-block;
    position: static;
    width: auto;
    height: auto;
    background: none;
    color: #1d4259;
    vertical-align: middle;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 800px) {
  .header-middle__container {
    margin-left: 20vw;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 900px) {
  .tree-section {
    display: none;
  }
  .basket-section {
    right: 75px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 950px) {
  .header-middle__container {
    margin-left: 18vw;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 1200px) {
  .header-middle__container {
    margin-left: 190px;
  }
  .header-right__container {
    position: absolute;
    top: 46px;
    right: 0;
  }
  .basket-section {
    position: static;
    float: right;
  }
}
/*
    cast header banner na sp
*/
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 900px) {
  .header-banner {
    /*background-size: cover;
    background-position: 0 0;

    &--deco-1 {
        background-image: url(../../img/header-banner-hp-01.jpg);
    }
    &--deco-2 {
        background-image: url(../../img/header-banner-hp-02.jpg);
    }
    &--deco-3 {
        background-image: url(../../img/header-banner-hp-03.jpg);
    }
    &--deco-4 {
        background-image: url(../../img/header-banner-hp-04.jpg);
    }
    &--deco-5 {
        background-image: url(../../img/header-banner-hp-05.jpg);
    }
    &--deco-6 {
        background-image: url(../../img/header-banner-hp-06.jpg);
    }*/
  }
  .header-banner img {
    width: 100%;
  }
}
/*
  search / vyhledavani
 */
.search-section {
  position: absolute;
  top: calc(24vw + 45.2px);
  left: 68px;
  width: 48px;
  height: 48px;
  z-index: 25;
}

.search__icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  border: 1px solid #dadada;
  cursor: pointer;
}
.search__icon:hover, .search__icon:focus {
  border: 1px solid #1d4259;
}
.search__icon .svg-ico {
  fill: #1d4259;
  width: 20px;
  height: 20px;
}

.search__label {
  display: none;
}

.search-box form {
  display: none;
}

input.search-input {
  position: relative;
  z-index: 99999;
  float: left;
  width: calc(100% - 48px);
  padding-top: 14px;
  color: #1d4259;
  text-overflow: ellipsis;
}
input.search-input::-moz-placeholder {
  color: #161616;
}
input.search-input::placeholder {
  color: #161616;
}
input.search-input:focus {
  border-right: none;
  color: #161616;
}
input.search-input:focus::-moz-placeholder {
  color: #adadad;
}
input.search-input:focus::placeholder {
  color: #adadad;
}
input.search-input--c2a {
  border-color: #f58221;
  border-right: none;
}
input.search-input--c2a:focus {
  border-color: #f58221;
}

.search-button {
  position: relative;
  z-index: 99999;
  float: left;
  width: 48px;
  height: 48px;
  padding: 0;
  border: 1px solid #007bc4;
  font-size: 0;
  background: #007bc4;
}
.search-button:hover, .search-button:focus {
  background: #fff;
}
.search-button:hover .svg-ico, .search-button:focus .svg-ico {
  fill: #1d4259;
}
.search-button.but--c2a {
  background-color: #f58221;
  border: 1px solid #f58221;
}
.search-button.but--c2a .svg-ico {
  fill: #fff;
}
.search-button.but--c2a:hover, .search-button.but--c2a:focus {
  background-color: #fff;
  color: #f58221;
}
.search-button.but--c2a:hover .svg-ico, .search-button.but--c2a:focus .svg-ico {
  fill: #f58221;
}
.search-button.but--c2a:active {
  background-color: #f58221;
  border: 1px solid #f58221;
  color: #fff;
}
.search-button .svg-ico {
  fill: #fff;
  width: 20px;
  height: 20px;
}

.search__choice {
  clear: both;
  padding-top: 30px;
}

.search__choice-item {
  display: inline-block;
}
.search__choice-item:first-child {
  margin-right: 20px;
}
.search__choice-item label {
  display: inline-block;
}

.search-box.is-visible form {
  display: block;
  position: absolute;
  top: -14px;
  left: -88px;
  width: 100vw;
  min-width: 300px;
  padding: 60px 20px 40px;
  border: 1px solid #efefef;
  background: #fff;
  z-index: 1000;
  box-shadow: rgba(22, 22, 22, 0.5) 0 0 20px;
}
.search-box.is-visible form .search-input {
  width: calc(100% - 48px);
  padding: 14px 3vw 12px;
}
.search-box.is-visible form .expander-close {
  display: block;
  top: 8px;
  right: 8px;
}

#search__autocomplete {
  position: relative;
  z-index: 100;
}
#search__autocomplete ul {
  background-color: #fff;
  clear: both;
  max-height: 80vh;
  overflow: auto;
  margin: 0;
  padding: 0;
  list-style: none;
}
#search__autocomplete li {
  margin: 0;
  padding: 5px 10px;
  list-style: none;
  cursor: pointer;
}
#search__autocomplete li:hover, #search__autocomplete li:has(> a.ui-state-active) {
  background-color: #f7f7f7;
}
#search__autocomplete li::before {
  display: none;
}
#search__autocomplete li span {
  display: block;
}
#search__autocomplete a {
  text-decoration: none;
}

a .ui-menu-item__name {
  font-weight: bold;
  color: #1d4259;
}

a .ui-menu-item__subheading {
  font-size: 12px;
  color: #007bc4;
}

/* .ui-helper-hidden-accessible {
  background: $grey;
    font-size: 1px;
    color: $grey; 
}*/
@supports (-webkit-appearance: none) {
  .ui-helper-hidden-accessible {
    position: absolute;
    top: -99999px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 370px) {
  .search-section {
    top: calc(-3.056768559vw + 145.3100436681px);
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 500px) {
  .search-box.is-visible form {
    padding-right: 30px;
    padding-left: 30px;
  }
  .search-box.is-visible form .search-input {
    padding: 14px 16px 12px;
  }
  .search-box.is-visible form .expander-close {
    right: 18px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 600px) {
  .search-section {
    top: 127px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 775px) {
  .search-box.is-visible form {
    top: 0;
    left: -88px;
    width: 450px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 900px) {
  .search-section {
    position: absolute;
    right: 0;
    left: auto;
    top: 46px;
    z-index: 3;
  }
  .search__icon {
    border: 1px solid #cce5f3;
  }
  .search-box.is-visible form {
    left: auto;
    right: -20px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 1200px) {
  .search-section {
    position: relative;
    top: 0;
    float: right;
    width: 316px;
  }
  .search__icon {
    display: none;
  }
  .search-box form {
    display: block;
    width: 296px;
  }
  .search__choice {
    display: none;
  }
  .search__choice.is-visible {
    display: block;
    position: absolute;
    top: 48px;
    padding: 20px;
    border: 1px solid #cce5f3;
    border-top: 0;
    background: #fff;
    z-index: 200;
    min-width: 296px;
  }
  .search-box.is-visible form {
    position: static;
    float: right;
    width: 316px;
    height: auto;
    padding: 0 20px 0 0;
    border: none;
    background: none;
    box-shadow: none;
  }
  .search-box.is-visible form .expander-close {
    display: none;
  }
  #search__autocomplete ul {
    border: 1px solid #cce5f3;
    border-top: 0;
    padding-top: 10px;
  }
}
/*
hlavni a vedlejsi navigace
*/
.menu {
  position: absolute;
  top: calc(24vw + 84.2px);
  left: 0;
  right: 0;
  height: 70px;
  margin: 0 auto;
  padding: 9px 0;
  background: #efefef;
}

.menu-list {
  background: #007bc4;
  display: none;
  width: calc(100% + 40px);
  margin: 0 -20px;
  padding: 0;
  font-weight: 700;
}
.is-visible .menu-list {
  display: block;
}
.menu-list li {
  position: relative;
  padding: 0;
  margin: 0;
}
.menu-list li:before {
  display: none;
}
.menu-list li a {
  display: block;
  border-top: 1px solid #248dcb;
  margin: 0;
  padding: 17px 20px 15px;
  color: #fff;
  text-decoration: none;
}
.menu-list li a:hover, .menu-list li a.active {
  background: #012b44;
  color: #fff;
  transition: 0.1s;
}
.menu-list li a.home-link {
  border-top: 0;
}
.menu-list li.open-sublist .menu-sublist {
  display: block;
}
.menu-list li.open-sublist .sublist__expander {
  background: var(--url-close-w) 50%/20px no-repeat;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .menu-list li.open-sublist .sublist__expander {
    background: url(../../gfx/close-w.svg) 50%/20px no-repeat;
  }
}
.menu-list .sublist__expander {
  position: absolute;
  display: block;
  top: 1px;
  right: 3px;
  width: 55px;
  height: 52px;
  background: var(--url-open-w) 50%/20px no-repeat;
  cursor: pointer;
  content: "";
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .menu-list .sublist__expander {
    background: url(../../gfx/open-w.svg) 50%/20px no-repeat;
  }
}
.menu-list ul a {
  padding-left: 40px;
  color: #1d4259;
}

.menu-sublist {
  background: #f7f7f7;
  box-shadow: inset 0px 15px 10px -12px #868484, inset 0px -14px 12px -12px #989898;
  display: none;
  margin: 0;
  padding: 0;
}
.menu-list > li:last-child .menu-sublist {
  box-shadow: inset 0px 15px 10px -12px #868484;
}

.menu-show {
  position: relative;
  float: right;
  padding-right: 60px;
  width: auto;
  height: 54px;
  color: #007bc4;
  font-weight: 700;
  line-height: 52px;
  text-decoration: none;
}
.menu-show:focus .svg-ico {
  color: #007bc4;
}
.menu-show:focus .svg-ico .border {
  stroke: #007bc4;
  stroke-width: 4;
}
.menu-show:focus .svg-ico .lines {
  fill: #007bc4;
}
.menu-show:hover .svg-ico .border {
  stroke: #1d4259;
  stroke-width: 4;
}
.menu-show:hover .svg-ico .lines {
  fill: #1d4259;
}
.menu .menu-show:hover .svg-ico {
  color: #1d4259;
}
.menu-show .svg-ico {
  left: auto;
  right: 0;
  transform: translate(0, -50%);
  width: 48px;
  height: 48px;
}
.menu-show .svg-ico .border {
  fill: none;
  stroke: #007bc4;
  stroke-width: 4;
}
.menu-show .svg-ico .lines {
  fill: #007bc4;
}
.menu-show span {
  display: none;
}

.submenu-box {
  display: none;
  margin-bottom: 26px;
}
.submenu-box h3 {
  margin: 0;
  font-size: 1rem;
}
.submenu-box h3 a,
.submenu-box h3 span {
  display: block;
  padding: 20px;
  text-decoration: none;
}
.submenu-box ul {
  margin: 0;
  padding: 20px;
  border: 1px solid #efefef;
  border-top: 0;
}
.submenu-box ul li a {
  display: block;
  margin-left: 6px;
  font-weight: 700;
  text-decoration: none;
}
.submenu-box ul li a:hover, .submenu-box ul li a:focus {
  text-decoration: underline;
}
.eshop .submenu-box ul li a {
  font-weight: 400;
}
.submenu-box ul .active {
  color: #007bc4;
  text-decoration: underline;
}
.submenu-box li {
  margin-bottom: 20px;
}
.submenu-box li:last-child {
  margin-bottom: 0;
}

/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 345px) {
  .menu-show span {
    display: inline;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 370px) {
  .menu {
    top: calc(-3.056768559vw + 184.3100436681px);
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
/* @media screen and (min-width: 450px) {
  .menu-list.is-visible {
    left: auto;
    width: 300px;
  }
} */
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 600px) {
  .menu {
    top: 115px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 900px) {
  .menu {
    height: 67px;
    background: #007bc4;
    padding: 0;
  }
  .menu .wrap {
    margin: 0;
  }
  .menu-list, .menu-list.is-visible {
    position: static;
    display: flex;
    width: auto;
    margin: 0;
    font-size: 0;
    text-align: center;
  }
  .menu-list li, .menu-list.is-visible li {
    flex: auto;
    display: inline-block;
    margin: 0;
    padding: 0;
    font-size: 0;
    text-align: center;
  }
  .menu-list li:first-child, .menu-list.is-visible li:first-child {
    flex: none;
    width: 71px;
  }
  .menu-list li:last-child a, .menu-list.is-visible li:last-child a {
    border-right: none;
  }
  .menu-list li a, .menu-list.is-visible li a {
    display: block;
    padding: 0;
    border-top: 0;
    border-right: 1px solid #248dcb;
    font-size: 15px;
    line-height: 67px;
  }
  .menu-list li a:hover, .menu-list li a.active, .menu-list.is-visible li a:hover, .menu-list.is-visible li a.active {
    background: linear-gradient(rgba(255, 255, 255, 0.11) 2%, rgba(0, 0, 0, 0.11) 98%), #012b44;
  }
  .menu-list .expander-close, .menu-list.is-visible .expander-close {
    display: none;
  }
  .menu .home-link {
    height: 100%;
  }
  .menu .home-link__txt {
    position: absolute;
    top: -99999px;
  }
  .menu .home-link__ico {
    background: url(../../gfx/home-w.svg) center center/21px 21px no-repeat;
    display: block;
    height: 100%;
  }
  .menu-show {
    display: none;
  }
  .submenu-box {
    display: block;
  }
  .menu .sublist__expander,
  .menu-sublist,
  .menu li.open-sublist .menu-sublist {
    display: none;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 1200px) {
  .menu {
    left: calc(50% - 585px);
    right: calc(50% - 585px);
    max-width: 1170px;
  }
  .menu li a {
    font-size: 1.143rem;
  }
  .menu li:first-child {
    flex: 1;
  }
}
/*
    drobeckova navigace
*/
.breadcrumbs__container {
  position: relative;
  margin: -9px -20px 16px -20px;
}
.breadcrumbs__container:before, .breadcrumbs__container:after {
  content: "";
  background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 100%;
  z-index: 5;
  pointer-events: none;
}
.breadcrumbs__container:after {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  left: auto;
  right: 0;
}

.breadcrumbs__container__helper {
  white-space: nowrap;
  direction: rtl;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.breadcrumbs__container__helper::-webkit-scrollbar {
  width: 0 !important;
}

.breadcrumbs {
  display: inline-block;
  min-width: 100%;
  padding: 0 20px;
  text-align: left;
}
.breadcrumbs a {
  margin-right: 8px;
  text-decoration: none;
}
.breadcrumbs a:hover, .breadcrumbs a:focus {
  text-decoration: underline;
}
.breadcrumbs span:not(:last-child) {
  margin-right: 5px;
}
.breadcrumbs span:not(:last-child):after {
  content: ">";
}

/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 900px) {
  .breadcrumbs__container {
    margin: -9px 0 16px 0;
  }
  .breadcrumbs__container:before, .breadcrumbs__container:after {
    display: none;
  }
  .breadcrumbs__container__helper {
    white-space: normal;
    direction: ltr;
    overflow: visible;
  }
  .breadcrumbs {
    display: block;
    min-width: 0;
    margin-left: 20px;
    padding: 0;
  }
  .breadcrumbs span {
    display: inline-block;
  }
}
/*
    horni pruh paticky
*/
.footer {
  background: #efefef;
  font-size: 0.857rem;
  color: #383838;
}
.footer a {
  text-decoration: none;
}
.footer a:hover, .footer a:focus {
  color: #007bc4;
  text-decoration: underline;
}

.footer-top {
  line-height: 1.8;
}
.footer-top a {
  color: #383838;
}

.partner-box {
  margin: 26px 0;
  padding: 20px;
  border: 1px solid #bfd8e6;
}
.partner-box .fbox-1 {
  margin-bottom: 24px;
}
.partner-box .fbox-1 img {
  float: left;
  margin-right: 25px;
}
.partner-box .fbox-1 span {
  float: left;
  width: calc(82.5vw + -181px);
  max-width: 148px;
}
.partner-box .fbox-2 {
  line-height: 1.4;
}
.partner-box .fbox-2 img {
  float: left;
  margin: 0 28px 10px 0;
}
.partner-box .more {
  display: inline;
  clear: none;
  padding: 0 20px;
}

.f-guality {
  margin-bottom: 10px;
  padding-top: 3px;
}

.logo--contitech {
  background: #ffffff;
}

.contact-box {
  margin-top: 30px;
}
.contact-box .centering {
  min-width: 258px;
}
.contact-box .fbox-1 {
  float: left;
  margin-right: 12vw;
}
.contact-box .fbox-2 {
  float: left;
  /* p {
          margin-bottom: 0;
      } */
}
.contact-box .fbox-3 {
  clear: both;
}
.contact-box .fbox-4 img {
  vertical-align: middle;
}

.contact-links-box {
  padding: 15px 0;
}
.contact-links-box ul {
  margin: 0;
  padding-left: 0;
}
.contact-links-box li {
  margin: 0;
  padding: 16px 20px;
  border-bottom: 1px solid #cce5f3;
  background: url(../../gfx/next-b.svg) left center no-repeat;
}
.contact-links-box li a {
  text-decoration: none;
}

/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 400px) {
  .partner-box .fbox-2 {
    line-height: 1;
  }
  .f-guality {
    margin-bottom: 20px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 650px) {
  .partner-box {
    float: left;
    width: 350px;
    margin-top: 30px;
    margin-right: 20px;
  }
  .contact-box {
    /* float: left;
        width: calc(100% - 350px); */
    margin-top: 25px;
  }
  .contact-box .centering {
    width: auto;
    min-width: auto;
    display: block;
  }
  .contact-box .fbox-1,
  .contact-box .fbox-2 {
    float: none;
    margin-right: 0;
  }
  .contact-box .fbox-3 {
    clear: none;
    /*padding-top: 21px;
          margin-bottom: 16px;*/
  }
  .contact-box .fbox-4 {
    /*margin-bottom: 26px;
          padding-top: 14px;*/
    clear: both;
    text-align: center;
  }
  .contact-links-box {
    text-align: center;
  }
  .contact-links-box li {
    background: none;
    display: inline-block;
    border-bottom: 0;
    padding: 16px 1vw;
  }
  .footer-line {
    border-bottom: 1px solid #cce5f3;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 800px) {
  .contact-box .fbox-1 {
    float: left;
    width: 120px;
    /* margin-right: stretch(37, 90, 950, 1200);*/
    margin-right: 20px;
  }
  .contact-box .fbox-2 {
    float: left;
    width: calc(100% - 350px - 20px - 120px - 20px);
  }
  .contact-box .fbox-3 {
    float: left;
    width: calc(100% - 350px - 20px - 120px - 20px);
    margin-bottom: 16px;
    padding-top: 9px;
  }
  .contact-box .fbox-4 {
    clear: none;
    text-align: left;
  }
  .contact-links-box {
    text-align: center;
  }
  .contact-links-box li {
    background: url(../../gfx/next-b.svg) left center no-repeat;
    padding: 16px 20px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 950px) {
  .partner-box {
    margin-top: 26px;
  }
  .contact-box {
    float: left;
    width: calc(100% - 350px - 225px - 40px);
    margin-top: 47px;
    margin-bottom: 12px;
  }
  .contact-box .centering {
    display: table;
  }
  .contact-box .fbox-1 {
    width: 50%;
  }
  .contact-box .fbox-2 {
    width: auto;
  }
  .contact-box .fbox-3 {
    clear: both;
    width: auto;
    margin-bottom: 0;
    padding-top: 0;
  }
  .contact-box .fbox-4 {
    clear: both;
  }
  .contact-links-box {
    float: right;
    width: 225px;
    margin: 35px 0 0;
    padding: 0;
    text-align: left;
  }
  .contact-links-box li {
    display: list-item;
    padding: 16px 20px;
  }
  .contact-links-box li:not(:last-child) {
    border-bottom: 1px solid #cce5f3;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 1200px) {
  .partner-box {
    margin: 26px 10.855% 26px 0;
  }
  .contact-box {
    width: 39.999%;
  }
  .contact-box .centering {
    width: 100%;
    margin: 0;
  }
  .contact-box .fbox-1 {
    width: 50%;
    margin-right: 0;
  }
  .contact-links-box {
    width: 19.231%;
  }
}
/*
    horni pruh paticky
*/
.footer-middle {
  padding: 15px 0;
  text-align: center;
}
.footer-middle ul {
  margin: 0;
  padding-left: 0;
}
.footer-middle li {
  display: inline-block;
  margin: 0;
  padding-left: 0;
}
.footer-middle li:before {
  display: none;
}
.footer-middle .split {
  padding: 0 7px 0 9px;
  color: #007bc4;
}

.footer-middle__left {
  display: none;
}

.footer-middle__right {
  padding-top: 16px;
}

.footer-bottom {
  padding: 15px 0;
  text-align: center;
}
.footer-bottom p {
  margin: 0;
}

.footer-bottom__left {
  margin-bottom: 30px;
}

.footer .claim {
  display: block;
}

/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 500px) {
  .footer .claim {
    display: inline;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 1000px) {
  .footer-middle {
    padding: 22px 0 0;
  }
  .footer-middle__left {
    display: block;
    float: left;
    margin-bottom: 0;
    text-align: left;
  }
  .footer-middle__right {
    float: right;
    padding-top: 0;
    text-align: right;
  }
  .footer-bottom {
    padding: 15px 0 40px;
  }
  .footer-bottom__left {
    float: left;
    margin-bottom: 0;
    text-align: left;
  }
  .footer-bottom__right {
    float: right;
    text-align: right;
  }
}
/*
    sidebar boxy
*/
.sidebar__info-box {
  max-width: 420px;
  margin: 0 auto 26px;
  padding: 32px 20px 20px;
  background-color: #efefef;
  color: #1d4259;
}
.sidebar__info-box p {
  margin-bottom: 0;
}
.sidebar__info-box span {
  margin-right: 23px;
}
.sidebar__info-box .phone {
  display: block;
  margin: 3px 0 8px;
  padding-left: 40px;
  font-size: 22px;
  text-decoration: none;
  background: url(../../gfx/phone-b.svg) 3px 8px no-repeat;
  font-weight: 900;
}
.sidebar__info-box .more {
  margin-bottom: -15px;
  padding: 15px 20px;
}

.sidebar__info-box h2 {
  margin: 0 0 5px;
  font-size: 1.714rem;
  font-weight: 400;
}

.info-box--discount {
  background-image: url(../../gfx/discount-w.svg?v=2);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 100px 100px;
}

.sidebar__newsletter-box {
  max-width: 420px;
  margin: 0 auto 26px;
  padding: 32px 20px 20px;
  background-color: #efefef;
  color: #1d4259;
}
.sidebar__newsletter-box h2 {
  position: relative;
  margin: 0 0 5px;
  font-size: 1.714rem;
  font-weight: 400;
  background: url(../../gfx/email-bl.svg) right -3px no-repeat;
}
.sidebar__newsletter-box h2 label {
  display: block;
}
.sidebar__newsletter-box form label.label-email {
  display: inline-block;
  margin: 0 0 3px 0;
}
.sidebar__newsletter-box input {
  height: 42px;
  margin-top: 4px;
}
.sidebar__newsletter-box [type=email] {
  grid-area: field;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  border: 1px solid #fff;
}
.sidebar__newsletter-box [type=email]:focus {
  border-right: none;
}
.sidebar__newsletter-box [type=email].validated {
  /* Change the white to any color */
}
.sidebar__newsletter-box [type=email].validated:valid, .sidebar__newsletter-box [type=email].validated:invalid {
  border-right: 0;
}
.sidebar__newsletter-box [type=email].validated:-webkit-autofill, .sidebar__newsletter-box [type=email].validated:-webkit-autofill:hover, .sidebar__newsletter-box [type=email].validated:-webkit-autofill:focus, .sidebar__newsletter-box [type=email].validated:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}
.sidebar__newsletter-box .but {
  grid-area: button;
  -ms-grid-column: 2;
  -ms-grid-row: 1;
  width: 44px;
  padding: 0 11px;
}
.sidebar__newsletter-box .but:hover, .sidebar__newsletter-box .but:focus {
  background-color: #fff;
  border: 1px solid #1d4259;
}
.sidebar__newsletter-box .but:active {
  border: 1px solid #adadad;
}
.sidebar__newsletter-box .label-helper label {
  font-weight: 400;
}
.sidebar__newsletter-box .more {
  margin-bottom: -15px;
  padding: 15px 20px;
}

.newsletter-box__wrap {
  -ms-grid-columns: 1fr auto;
  display: grid;
  grid-template-areas: "field button";
  grid-template-columns: 1fr auto;
}

.sidebar__selfpromo-box {
  max-width: 420px;
  margin: 0 auto 26px;
}

.shop-sidebar .currency {
  margin-left: 0.3em;
}
.shop-sidebar .sum-price {
  float: right;
  text-align: right;
  font-weight: 700;
}

.sidebar__summary {
  max-width: 420px;
  margin: 0 auto 26px;
}
.sidebar__summary h2 {
  margin-bottom: 20px;
  color: #161616;
}

.sidebar__items {
  padding: 10px 0;
  background: #efefef;
}
.sidebar__items > div {
  margin: 0 20px;
  padding: 12px 0 0 40px;
}
.sidebar__items > div:not(:last-child) {
  border-bottom: 1px solid #cce5f3;
}
.sidebar__items p {
  margin: 0 0 3px 0;
  clear: both;
}
.sidebar__items p:first-child {
  margin-bottom: 0;
  font-weight: 700;
}
.sidebar__items p:last-child {
  margin: 13px 0 0;
  padding-bottom: 12px;
}
.sidebar__items .pcs-per-kind {
  float: left;
  min-width: 22px;
  margin-right: 4px;
  margin-left: -39px;
}
.sidebar__items .item-title {
  color: #007bc4;
}
.sidebar__items .sum-price {
  color: #007bc4;
}

.sidebar__sums {
  padding: 23px 22px;
  background: #007bc4;
  color: #fff;
  font-size: 1.093rem;
  /*.sum-title {
    display: inline-block;
    padding: 0;
  }

  p:first-child .sum-title {
    margin-top: 3px;
  } */
}
.sidebar__sums p {
  margin: 0;
  display: flex;
  align-items: baseline;
}
.sidebar__sums p:first-child {
  margin-bottom: 10px;
}
.sidebar__sums .sum-title {
  flex: auto;
  text-align: left;
}
.sidebar__sums .sum-price {
  flex: auto;
  white-space: nowrap;
}
.sidebar__sums .sum-without-VAT {
  font-size: 1.214rem;
}

/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 350px) and (max-width: 699px), screen and (min-width: 750px) and (max-width: 999px), screen and (min-width: 1050px) {
  .sidebar__sums {
    /* p:first-child .sum-title {
      margin-top: 5px;
    } */
  }
  .sidebar__sums .sum-without-VAT {
    font-size: 1.429rem;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 400px) {
  .sidebar__items > div {
    padding-right: 20px;
  }
  .sidebar__sums p {
    margin-right: 18px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 600px) {
  .sidebar .flex-cont {
    display: flex;
    flex-wrap: wrap;
  }
  .sidebar__info-box,
  .sidebar__newsletter-box {
    width: calc(50% - 10px);
  }
  .sidebar__newsletter-box {
    margin-right: 20px;
  }
  .info-box--discount {
    max-width: 100%;
    width: 100%;
  }
  .shop-sidebar .sidebar__info-box {
    width: 420px;
  }
  .sidebar__selfpromo-box {
    max-width: 100%;
    width: 100%;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 800px) {
  .shop-sidebar {
    float: left;
    width: 45%;
  }
  .sidebar__summary,
  .sidebar__info-box {
    max-width: none;
  }
  .sidebar .flex-cont {
    display: nowrap;
  }
  .sidebar__summary h2 {
    margin-top: 0;
  }
  .sidebar__info-box,
  .sidebar__newsletter-box {
    width: auto;
    margin: 0;
    flex: 1;
  }
  .shop-sidebar .sidebar__info-box {
    width: 100%;
  }
  .sidebar__info-box {
    float: none;
    width: 100%;
    margin: 0;
  }
  .info-box--discount {
    max-width: 420px;
  }
  .sidebar__newsletter-box {
    margin: 0 20px;
  }
  .sidebar__newsletter-box h2 {
    background: none;
  }
  .sidebar__selfpromo-box {
    margin-top: 26px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 900px) {
  .sidebar .flex-cont {
    display: block;
  }
  .sidebar__info-box,
  .sidebar__newsletter-box {
    float: none;
    width: 100%;
    margin: 0 auto 26px;
  }
  .sidebar__newsletter-box h2 {
    background: url(../../gfx/email-bl.svg) right -3px no-repeat;
  }
  .sidebar__selfpromo-box {
    margin-top: 0;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 1000px) {
  .sidebar__info-box .but {
    padding: 14px 15px;
  }
  .shop-sidebar .sidebar__info-box .but {
    padding: 14px 20px;
  }
  .shop-sidebar {
    width: calc((100% - 40px) / 3);
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 1200px) {
  .sidebar__sums .sum-without-VAT {
    font-size: 1.643rem;
  }
  .sidebar__sums p {
    margin-left: 38px;
    /*&:first-child .sum-title {
      margin-top: 10px;
    }*/
  }
  .sidebar__sums p:first-child {
    margin-bottom: 7px;
  }
  .shop-sidebar .sidebar__info-box span {
    margin-right: 39px;
  }
}
/*
    ruzne komponenty v nakupnim procesu
 */
.field__container {
  margin-bottom: 30px;
  padding: 0 20px;
  border: 1px solid #efefef;
}
.field__container > label {
  margin: 0 0 15px 0;
}
.field__container > [type=checkbox]:not(:checked) + label + fieldset {
  display: none;
}

.form-row {
  margin: 0;
}
.form-row-radio {
  margin-top: 16px;
}
.form-row label {
  display: block;
  margin-bottom: 8px;
  padding-left: 1px;
}
.form-row label.inline-block {
  display: inline-block;
}
.form-row [type=checkbox] + label {
  display: block;
  position: relative;
  padding-left: 36px;
}
.form-row [type=checkbox]:not(:disabled) + label {
  cursor: pointer;
}
.form-row [type=radio] + label {
  display: block;
  position: relative;
  padding: 3px 0 3px 36px;
}
.form-row [type=radio]:not(:disabled) + label {
  cursor: pointer;
}
.form-row:not(:last-child) {
  margin-bottom: 15px;
}
.form-row-short label {
  width: 33.3333333333%;
}
.form-row-short input[type=text],
.form-row-short input[type=password],
.form-row-short input[type=email],
.form-row-short input[type=tel],
.form-row-short input[type=number],
.form-row-short textarea {
  width: 33.3333333333%;
}
.form-row-gdpr, .form-row-but, .form-row-gdpr:not(:last-child), .form-row-but:not(:last-child) {
  margin-bottom: 25px;
}
.form-row-psw {
  font-size: 12px;
  line-height: 22px;
  color: #adadad;
}
.form-row-dcountry, .form-row-dcountry:not(:last-child) {
  margin-bottom: 40px;
}

input[type=text].in-radio-list {
  width: calc(100% - 36px);
  margin-left: 36px;
}

label.in-radio-list {
  width: calc(100% - 36px);
  margin-top: -8px;
  margin-left: 36px;
}

/* .star-note {
    margin: 25px 0 20px;
    color: $blue;
    span {
        float: left;
        position: relative;
        margin-right: 5px;
        top: 2px;
        font-size: 1.786em;
        line-height: 1;
    }
} */
.coupon {
  margin-bottom: 30px;
}
.coupon p {
  margin-bottom: 0;
}
.coupon .txt {
  float: left;
  width: calc(100% - 98px);
  max-width: 366px;
  border-right: 0;
  text-overflow: ellipsis;
}
.coupon .txt ~ .but {
  float: left;
  width: 98px;
}
.coupon .coupon-list .but {
  margin: 14px 0 0;
}

.eshop-summary-box,
.order-textarea {
  margin-bottom: 30px;
}

p.order-split-note {
  margin: 0 0 1em 36px;
  font-size: 0.857rem;
}

.table--payment-info td {
  vertical-align: top;
}

img.qr-code {
  border: 1px solid #000;
}

/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 400px) {
  .fieldset-box {
    border: 1px solid #efefef;
    padding: 20px;
  }
  .fieldset-box-like {
    border: 0;
    padding: 15px 0;
  }
  .form-row {
    margin: auto;
  }
  .form-row-radio {
    margin-top: 16px;
  }
  .form-row-gdpr, .form-row-but {
    width: 100%;
    text-align: center;
  }
  .form-row-gdpr .form-row__helper, .form-row-but .form-row__helper {
    display: inline-block;
    width: auto;
  }
  legend.form-row {
    width: auto;
    margin: 0 0 8px 0;
  }
  .fieldset-box legend.form-row {
    margin: 0 -10px 8px -10px;
  }
  legend.form-row span {
    display: inline-block;
    width: auto;
    padding: 0 10px;
  }
  /*.choose-customer__reg form:after {
      left: 62%;
  }*/
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 432px) {
  .form-row {
    width: 350px;
  }
  .form-row-gdpr, .form-row-but {
    width: 100%;
  }
  .form-row-gdpr .form-row__helper, .form-row-but .form-row__helper {
    width: auto;
  }
  legend.form-row {
    width: auto;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
/*@media screen and (min-width: 500px) {
    .form-row {

        &.half-part {
            float: left;
            width: calc(50% - 10px);
        }

        &.ml20 {
            margin-left: 20px;
        }
    }

    .half-part.cll:nth-last-child(2) {
        margin-bottom: 0;
    }
}*/
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 600px) {
  .table--payment-info {
    width: 400px !important;
  }
  .module-function-orderinfo .table--payment-info {
    width: auto !important;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 800px) {
  .shopping-main {
    float: left;
    width: calc(55% - 20px);
    margin-right: 20px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 1000px) {
  .shopping-main {
    width: calc((100% - 40px) / 3 * 2 + 20px);
  }
  /*.choose-customer__reg {
      float: left;
      width: 35%;
      margin-right: 20px;

      form:after {
          right: 20px;
          left: auto;
      }

      input[type="submit"] {
          width: auto;
      }
  }*/
  /*.choose-customer__unreg {
      float: left;
      width: calc(65% - 20px);

      h2 {
          margin: 0 0 20px;
      }
  }*/
}
.counter-box {
  display: inline-block;
}
.counter-box--disabled {
  opacity: 0.5;
}

.counter-box__label {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
}
.counter-box__label .field-error {
  position: absolute;
  left: 0;
  top: 100%;
  display: block;
  margin-top: 5px;
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
}

.counter-box__input {
  width: 3.143rem;
  background-color: #fff;
  border: 1px solid #bfd8e6;
  color: #161616;
  text-align: center;
  flex-shrink: 0;
}
.counter-box__input:focus {
  outline: 0;
}
.counter-box--disabled .counter-box__input {
  cursor: not-allowed;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.count-buttons-wrapper {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #bfd8e6;
  border-right: 1px solid #bfd8e6;
  border-bottom: 1px solid #bfd8e6;
  flex-shrink: 0;
}

.button--plus, .button--minus {
  position: relative;
  z-index: 1;
  flex: 1;
  width: 1.643rem;
  padding: 0;
  border: none;
  background-color: #fff;
  outline: 0;
}
.counter-box--disabled .button--plus, .counter-box--disabled .button--minus {
  cursor: not-allowed;
}
.button--plus:focus, .button--minus:focus {
  z-index: 2;
  outline: 1px solid #1d4259;
}
.button--plus {
  border-bottom: 1px solid #bfd8e6;
}
.button--plus::before, .button--minus::before, .button--plus::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: "";
}
.button--plus::before, .button--minus::before {
  width: 9px;
  height: 1px;
  border-bottom: 1px solid #bfd8e6;
}
.counter-box:hover .button--plus::before, .counter-box:hover .button--minus::before {
  border-bottom: 1px solid #161616;
}
.counter-box--disabled:hover .button--plus::before, .counter-box--disabled:hover .button--minus::before {
  border-bottom: 1px solid #bfd8e6;
}
.counter-box .button--plus:focus::before, .counter-box .button--minus:focus::before {
  border-bottom: 1px solid #161616;
}
.counter-box--disabled .button--plus:focus::before, .counter-box--disabled .button--minus:focus::before {
  border-bottom: 1px solid #bfd8e6;
}
.button--plus::after {
  width: 1px;
  height: 9px;
  border-right: 1px solid #bfd8e6;
}
.counter-box:hover .button--plus::after {
  border-right: 1px solid #161616;
}
.counter-box--disabled:hover .button--plus::after {
  border-right: 1px solid #bfd8e6;
}
.counter-box .button--plus:focus::after {
  border-right: 1px solid #161616;
}
.counter-box--disabled .button--plus:focus::after {
  border-right: 1px solid #bfd8e6;
}

.counter-box__units {
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.counter-box--disabled .counter-box__units {
  cursor: not-allowed;
}

/*
    Main Menu for Mobile Devices
*/
.menu-box {
  background: #007bc4;
  box-shadow: rgba(22, 22, 22, 0.5) 0 0 20px;
  position: fixed;
  right: calc(-100% - 20px);
  top: 0;
  z-index: 99;
  width: 100%;
  max-width: 480px;
  height: 100%;
  padding: 20px;
  overflow-y: scroll;
  transition: right 0.25s ease;
  color: #fff;
  /* h2 {
    margin: .8em 0 .4em;
    font-size: rem(32);
    font-weight: $very-bold;
    color: $white;
  } */
}
.menu-box.is-visible {
  right: 0;
  transition: right 0.5s ease;
}
.menu-box__header {
  margin: 0 -20px;
  padding: 0 20px;
}
.menu-box__header a {
  text-decoration: none;
}
.menu-box svg {
  /* max-width: 100%;
  max-height: 100%;
  vertical-align: top; */
  fill: #fff;
}
.menu-box .expander-close {
  color: #fff;
}

/*
    Eshop Tree Navigation for Mobile Devices
*/
.tree-base--hidden {
  display: none;
}

.tree-box {
  background: #fff;
  box-shadow: rgba(22, 22, 22, 0.5) 0 0 20px;
  position: fixed;
  left: calc(-100% - 20px);
  top: 0;
  z-index: 99;
  width: 100%;
  max-width: 480px;
  height: 100%;
  padding: 20px;
  overflow-y: scroll;
  transition: left 0.25s ease;
}
.tree-box.is-visible {
  left: 0;
  transition: left 0.5s ease;
}
.tree-box__header {
  border-bottom: 1px solid #efefef;
  margin: 0 -20px;
  padding: 0 20px;
}
.tree-box__header a {
  text-decoration: none;
}
.tree-box h2 {
  margin: 0.8em 0 0.4em;
  font-size: 2.286rem;
  font-weight: 900;
}
.tree-box h2 a {
  color: #161616;
}
.tree-box p {
  font-weight: 700;
}
.tree-box p a {
  color: #007bc4;
}
.tree-box .nav-tree {
  border: 0;
}

/*
    dalsi styly mensich casti
*/
.form-newsletter-content form,
.fulltext_results form {
  padding: 32px 20px;
  border: 1px solid #efefef;
}
.form-newsletter-content .input-group,
.fulltext_results .input-group {
  margin: 0 0 20px 0;
}
.form-newsletter-content .input-group label,
.fulltext_results .input-group label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.result h2 {
  margin-bottom: 0.5rem;
  font-size: 1.143rem;
}
.result a {
  color: #007bc4;
  text-decoration: none;
}
.result a:hover, .result a:focus {
  color: #1d4259;
}
.result .highlight {
  background-color: #cce5f3;
}

.result__url {
  word-wrap: break-word;
}

/*
// EU cookies bar - before 2022
.cookie-banner {
  background-color: $grey;
  position: relative;
  z-index: 9999999999999999999;
  width: 100%;
  border-bottom: $main-border;
  text-align: center;

  .cookie-banner__txt {
    display: block;
    padding: 8px 20px;
  }

  .but {
    display: inline-block;
    border-color: $dark-grey;
    background-color: $dark-grey;
    color: $nero;
    cursor: pointer;
  }

  a {
    color: $nero;
  }
} */
.gdpr-field label {
  display: inline;
}
.gdpr-field .label-helper {
  display: inline-block;
  position: relative;
  padding-left: 36px;
  text-align: left;
  cursor: pointer;
}
.gdpr-field label.field-error[for^=osobni].active {
  padding-left: 36px;
  text-align: left;
  cursor: pointer;
}
.gdpr-field [type=checkbox] + .label-helper > label:before {
  content: "";
  position: absolute;
  left: 0;
  top: -1px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 1px solid #cce5f3;
  cursor: pointer;
}
.sidebar__newsletter-box .gdpr-field [type=checkbox] + .label-helper > label:before {
  background-color: #fff;
}
.gdpr-field [type=checkbox]:focus + .label-helper > label:before {
  border-color: #1d4259;
}
.gdpr-field [type=checkbox] + .label-helper > label:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 5px;
  width: 15px;
  height: 15px;
  background: url(../../gfx/check.svg) center center no-repeat;
  transition: all 0.1s;
}
.gdpr-field [type=checkbox]:not(:checked) + .label-helper > label:after {
  opacity: 0;
  transform: scale(0);
}
.gdpr-field [type=checkbox]:checked + .label-helper > label:after {
  opacity: 1;
  transform: scale(1);
}

.tooltip {
  position: relative;
  cursor: pointer;
}
.tooltip:focus {
  outline: 0;
}
.tooltip a {
  color: initial !important;
}
.tooltip:not(abbr, .qplus) {
  position: relative;
  display: inline-block;
  width: 19px;
  height: 19px;
  border: 1px solid;
  border-radius: 50%;
  margin-bottom: -0.35em;
  padding: 0 6.5px;
  font-weight: 700;
  font-size: 0.929rem;
  opacity: 0.5;
}
.tooltip:not(abbr, .qplus):before {
  content: "i";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.tooltip--dummy {
  display: inline-block;
  width: 19px;
  margin-left: 0.25em;
}

abbr.tooltip,
abbr.tooltip.no-css-hover {
  display: inline-block;
  opacity: 1;
}

.tooltip__txt {
  cursor: default;
  background: #efefef;
  position: absolute;
  top: 25px;
  z-index: 10;
  right: 0;
  display: none;
  width: 230px;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.23), -1px -1px 7px rgba(0, 0, 0, 0.13);
  padding: 10px;
  font-size: 0.929rem;
  color: black;
  font-weight: 400;
  text-align: left;
  white-space: normal;
}
.tooltip__txt .tooltip__headline {
  font-weight: 700;
}
.product__price-box .on-request .tooltip__txt {
  right: -98px;
}

.tooltip:hover {
  opacity: 1;
}
.tooltip:hover .tooltip__txt {
  display: block;
}

.tooltip.no-css-hover:hover .tooltip__txt {
  display: none;
}
.tooltip.no-css-hover {
  opacity: 0.5;
}
.tooltip.no-css-hover.active, .tooltip.no-css-hover.stick {
  opacity: 1;
}
.tooltip.no-css-hover.active .tooltip__txt, .tooltip.no-css-hover.stick .tooltip__txt {
  display: block;
}

.search-box .tooltip {
  z-index: 9999;
  margin-left: 12px;
}

.qplus {
  display: inline-block;
  width: 35px;
  margin-right: 5px;
  -webkit-tap-highlight-color: transparent;
  opacity: 0.5;
}
.qplus--incontent {
  width: 70px;
}
.qplus img {
  vertical-align: middle;
}
.qplus__wrapper {
  bottom: -10px;
  text-align: left;
}
.product-img .qplus {
  width: 70px;
}
.product-img .qplus__wrapper {
  position: absolute;
  top: 0;
  height: -moz-fit-content;
  height: fit-content;
}
.product-img .qplus .tooltip__txt {
  top: 39px;
}
.cat-item-img .qplus__wrapper {
  bottom: 0;
}

.local-menu ul {
  margin: 18px 0;
  padding-left: 0;
}
.local-menu ul li {
  display: inline-block;
  width: calc(100% - 26px);
  margin: 0 26px 10px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 375px) {
  .local-menu ul li {
    width: calc(50% - 26px);
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 400px) {
  .qplus__wrapper {
    position: absolute;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 480px) {
  .form-newsletter-content form .flex-helper,
  .fulltext_results form .flex-helper {
    display: flex;
    align-items: flex-end;
  }
  .form-newsletter-content .input-group,
  .fulltext_results .input-group {
    position: relative;
    flex: 1;
    margin: 0 20px 0 0;
  }
  .form-newsletter-content .field-error,
  .fulltext_results .field-error {
    position: absolute;
    left: 0;
    top: 100%;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 500px) {
  .local-menu ul li {
    display: inline-block;
    width: auto;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 940px) {
  .cookie-banner .cookie-banner__txt {
    display: inline-block;
  }
}

/*# sourceMappingURL=main.css.map */